import { ActivatedRoute } from '@angular/router';
import { EntityService } from 'app/shared/entity.service';
import { EnbdDomainPopupComponent } from 'app/third-party/enbd-domain-popup/enbd-domain-popup.component';
import { FrequencySelectboxEnum } from 'app/shared/enums/frequencyOptions';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FrameworkEnumTypes } from 'app/shared/enums/framework-enum-types.enum';
import { FileService } from 'app/shared/file.service';
import { UtilsService } from 'app/shared/utils.service';
import Papa from 'papaparse';
import { EnbdVendorConstant } from 'app/third-party/add-vendor/add-new-vendor-modal/enbd-vendor.constant';
import { ThirdPartyService } from 'app/third-party/third-party.service';
import { DomainFrameworkService } from 'app/shared/domain-framework.service';
@Component({
  selector: 'cygov-framework-frequency-section',
  templateUrl: './framework-frequency-section.component.html',
  styleUrls: ['./framework-frequency-section.component.scss'],
})
export class FrameworkFrequencySectionComponent implements OnInit {
  // STATIC DATA
  isENBD: boolean = UtilsService.isENBD;
  isInformatica: boolean = UtilsService.isInformatica || UtilsService.isInformaticaUat;

  isEnbdVendorRisk: boolean = false;
  @Input() assessmentData: any;
  @Output() assessmentDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() isBulkUpload: boolean = false;
  @Input() isFromDefaultSettings: boolean = false;
  @Input() entity: any;
  @Input() defaultSelectedDomains: string[] = [];

  riskList: any;
  currentClient = null;
  showAdvanceSettings: boolean = false;
  staticFrequencyCount: number;
  levelOptionsOrder = ['Full', 'High', 'Moderate', 'Light'];
  levelOptions;
  informaticaLevelOptions = ['Tier 1', 'Tier 2', 'Tier 3'];
  vendorCategories = [
    {
      name: 'Mandatory for all identified suppliers',
      numberOfQuestions: 0,
      isQualify: true,
      defaultSelected: true,
      id: 1,
    },
  ];

  enbdDomainList: any = [];

  artifactOptions = [
    {
      label: 'Suggested',
      value: 'suggested',
    },
    {
      label: 'Optional',
      value: 'optional',
    },
  ];
  artifactOptionsStandard = [
    {
      label: 'Suggested',
      value: 'suggested',
    },
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'None',
      value: 'none',
    },
  ];
  commentOptions = [
    {
      label: 'Suggested',
      value: 'suggested',
    },
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'None',
      value: 'none',
    },
  ];
  frequencyOpt = [
    // { name: 'Automatic Reminder', checked: false },
    { name: 'Automatically initiate re-assessment', checked: true },
  ];
  monthList = ['Annually', 'Bi-Annually', 'Quarterly'];

  timelineCheckBox = {
    AUTOMATIC_REMINDER: 'automatic reminder',
    AUTOMATIC_RE_ASSESSMENT: 'Automatic re-assessment',
  };
  // LOCAL MAIN VARIABLES
  selectedRiskIndex = 1;
  selectedFramework = null;
  selectedLevel = '';
  selectedArtOpt = '';

  // calendar options
  selectedStartDate: NgbDateStruct;
  selectedNextStartDateMin: NgbDateStruct;
  tempStartDate: NgbDateStruct;
  selectedEndDate: NgbDateStruct;
  endCalendar: NgbInputDatepicker;
  startCalendar: NgbInputDatepicker;
  startingDate: Date;
  deadlineDate: Date;
  DEADLINE_DATE = 'deadlineDate';
  ASSESSMENT_DATE = 'startingDate';
  showArtifactsToolTip: boolean = true;
  VendorRiskFrameworkEnum: any;
  modalRef = null;
  selectedDomains: any[] = [];
  requiredHeaders = ['category', 'subCategory', 'questionId'];
  parsedCSVData: any[];
  cachedDomain: any[] = [];
  allDomainList: any[] = [];
  isAdnoc: boolean = UtilsService.isAdnoc;
  maxFrequencyDays: number = 1826;

  assessmentObject: any;
  frameworkSettingsTooltips: any = {
    suggestedArtifact: {
      text: 'Require an artifact to be included with a "Yes" answer.',
      placement: 'right',
    },
    suggestedComments: {
      text: 'Require a comment to be included with any answer.',
      placement: 'right',
    },
  };
  frameworksData: any;

  constructor(
    private fileService: FileService,
    private modalService: NgbModal,
    private entityService: EntityService,
    private route: ActivatedRoute,
    private tpService: ThirdPartyService,
    private domainFrameworkService: DomainFrameworkService
  ) {
    this.VendorRiskFrameworkEnum = this.fileService.importFrameworkEnumsFromS3(
      FrameworkEnumTypes.VENDOR_RISK_FRAMEWORK_ENUM
    );
  }

  async ngOnInit(): Promise<void> {
    this.tpService?.previousAssessment$.subscribe((circle: any) => {
      this.assessmentObject = circle;
    });
    this.frameworksData = this.domainFrameworkService.getFrameworkJsonData();

    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId') || this.entity.id;

    // if we don't open enbd popup it will pass default enbd domains to assessment data
    if (this.isENBD) {
      if (!this.entity) {
        this.entity = await this.entityService.getEntity(entityId);
      }
      // this code was showing those domains those are selected from default settings
      // but for ENBD everytime domain will be selected on basis of categories so commented this code

      // this.enbdDomainList = this.entity?.defaultSetting?.enbdDomain
      //   ? this.entity?.defaultSetting?.enbdDomain
      //   : this.enbdDomainList;

      this.enbdDomainList = this.defaultSelectedDomains;
      // this line is for bulk upload vendor and default settings
      // where we cannot filter domain on basis of categories
      // so we are showing only default selected domains
      this.enbdDomainList = this.enbdDomainList.length
        ? this.enbdDomainList
        : EnbdVendorConstant.categories[0].defaultDomains;

      // if we have added domains and we move across modal pages
      // don't let new selected domains got un-selected
      this.enbdDomainList = this.assessmentData?.enbdDomains?.length
        ? this.assessmentData.enbdDomains
        : this.enbdDomainList;

      this.defaultSelectedDomains = this.assessmentData?.enbdDomains?.length
        ? this.assessmentData.enbdDomains
        : this.defaultSelectedDomains;

      this.cachedDomain = this.enbdDomainList;

      this.enbdDomainList?.forEach(domain => {
        this.selectedDomains.push(domain?.name ? domain.name : domain);
      });
      // only store selected domains when we land on this page for the first time
      this.assessmentData.enbdDomains = this.assessmentData?.enbdDomains?.length
        ? this.assessmentData.enbdDomains
        : this.selectedDomains;
    }
    this.frequencyOpt.forEach(frequency => {
      frequency.checked =
        frequency.name.toLowerCase() === this.timelineCheckBox.AUTOMATIC_REMINDER.toLowerCase()
          ? this.assessmentData?.isAutoReminder
          : this.assessmentData?.isAutoReassessment;
    });
    this.showAdvanceSettings =
      this.assessmentData?.frequencyName?.toLowerCase() === FrequencySelectboxEnum.ADVANCE.toLowerCase();
    this.selectedFramework = this.VendorRiskFrameworkEnum.VENDOR_RISK;

    const parentEntity = await this.entityService.getEntity(entityId, true);
    const frameworkSettings = parentEntity?.frameworkSettings
      ? JSON.parse(parentEntity.frameworkSettings)
      : this.domainFrameworkService.domainSettingsData;
    let domainSettings = this.domainFrameworkService.domainSettingsData;
    if (!domainSettings) {
      await this.domainFrameworkService.getDomainSetting();
      const rawDomainSettings = this.domainFrameworkService.getSettingFromDomain.settings;
      domainSettings = rawDomainSettings ? JSON.parse(rawDomainSettings) : {};
    }
    const vendorFrameworks = [
      ...frameworkSettings.VENDOR_FRAMEWORKS.filter(
        framework =>
          !!framework.status &&
          domainSettings?.VENDOR_FRAMEWORKS.find(obj => obj.name === framework.name && obj.status === true)
      ),
    ];
    this.addTotalQuestionsFromFrameworksData(vendorFrameworks, this.frameworksData);
    const childrenFramework = this.formatFrameworksByGroup(vendorFrameworks);
    // add level options
    const riskList = childrenFramework.map(risk => {
      if (risk.children && risk.children.length > 0) {
        // Map children's names into the level array
        const childNames = risk.children.map(child => {
          const levelName = child.name.split(' ');
          let localName = '';
          for (const [index, name] of levelName.entries()) {
            localName = index === 0 ? name : localName + ' ' + name;
            if (localName.toLowerCase() === child.frameworkFamily.toLowerCase()) {
              // Return the remaining values starting from the matched index
              return levelName.slice(index + 1).join(' ');
            }
          }
        });
        return {
          ...risk,
          level: childNames,
        };
      } else {
        // Default to 'Full' if there are no children
        return {
          ...risk,
          level: ['Full'],
        };
      }
    });
    this.riskList = this.prioritizeRisks(riskList); // re-sort the risk list by prioritization
    // this.levelOptions = this.riskList[0]?.level;

    if (this.riskList && this.riskList.length > 0) {
      const firstFramework = this.riskList[0];

      // If the first framework has children
      if (firstFramework.children && firstFramework.children.length > 0) {
        this.levelOptions = firstFramework.children.map((child: any) => ({
          level: child.name.split(' ').pop(), // Extract the last word as the level
          totalQuestions: child.totalQuestions || 0, // Default to 0 if totalQuestions is missing
        }));
      }
      // If no children, fall back to level array
      else if (firstFramework.level && firstFramework.level.length > 0) {
        this.levelOptions = firstFramework.level.map((level: string) => ({
          level,
          totalQuestions: firstFramework.totalQuestions || 0, // Default for non-children frameworks
        }));
      } else {
        console.error('Framework does not have children or level');
        this.levelOptions = []; // Handle case where both children and level are missing
      }
    } else {
      console.error('riskList is empty or undefined');
      this.levelOptions = []; // Provide a fallback for an empty or undefined riskList
    }

    const selectedFramework = this.riskList.find(f => f.name.toUpperCase() === this.assessmentData.frameworkName);

    this.assessmentData.frameworkName =
      this.assessmentData.frameworkName && Object.keys(selectedFramework).length
        ? this.assessmentData.frameworkName
        : this.riskList[0]?.name?.toUpperCase();
    this.assessmentData.level =
      this.assessmentData.level && selectedFramework?.level.some(f => f.toUpperCase() === this.assessmentData.level)
        ? this.assessmentData.level
        : selectedFramework?.level?.[0]?.toUpperCase();
    // Sort the risk list by prioritization
    this.riskList = this.prioritizeRisks(riskList);
    const assessmentRisk = this.assessmentData.frameworkName
      ? this.riskList.find(risk => risk.name.toUpperCase() === this.assessmentData.frameworkName.toUpperCase())
      : this.riskList[0];

    // Select default framework by domain
    this.updateAssessmentData('framework', assessmentRisk);

    const localStart = new Date();
    const localEnd = new Date();
    localEnd.setDate(localStart.getDate() + (this.assessmentData?.assessmentDays ?? 30));
    console.log(
      'UtilsService.getDateInNgbDateStructFormat(localEnd);',
      UtilsService.getDateInNgbDateStructFormat(localEnd)
    );
    this.selectedStartDate = this.assessmentData?.startDate
      ? this.assessmentData.startDate
      : UtilsService.getDateInNgbDateStructFormat(localStart);
    this.selectedEndDate = this.assessmentData?.endDate
      ? this.assessmentData.endDate
      : UtilsService.getDateInNgbDateStructFormat(localEnd);

    // Date validation: Ensure end date is not before the start date
    const startDateObj = new Date(
      this.selectedStartDate.year,
      this.selectedStartDate.month - 1,
      this.selectedStartDate.day
    );
    const endDateObj = new Date(this.selectedEndDate.year, this.selectedEndDate.month - 1, this.selectedEndDate.day);

    if (endDateObj < startDateObj) {
      // If the end date is before the start date, set the end date to 30 days after the start date
      const newEndDate = new Date(startDateObj);
      newEndDate.setDate(newEndDate.getDate() + 30);
      this.selectedEndDate = this.assessmentData?.endDate
        ? this.assessmentData.endDate
        : UtilsService.getDateInNgbDateStructFormat(newEndDate);
    }
    this.startingDate = JSON.parse(JSON.stringify(this.selectedStartDate));
    const dateObject = UtilsService.ngbDateStructToDate(this.selectedStartDate);
    const nextDateObject = new Date(dateObject);
    nextDateObject.setDate(dateObject.getDate() + 1);
    this.selectedNextStartDateMin = UtilsService.getDateInNgbDateStructFormat(nextDateObject);
    this.tempStartDate = JSON.parse(JSON.stringify(this.selectedStartDate));
    this.deadlineDate = JSON.parse(JSON.stringify(this.selectedEndDate));
    this.assessmentData.startDate = JSON.parse(JSON.stringify(this.selectedStartDate));
    this.assessmentData.endDate = JSON.parse(JSON.stringify(this.selectedEndDate));
    this.assessmentDataChange.emit(this.assessmentData);

    if (this.isENBD) {
      this.isEnbdVendorRisk = this.assessmentData?.frameworkKey === 'VENDOR_RISK_ENBD';
      await this.changeFrameWork(this.assessmentData?.frameworkKey, true);
    }
  }
  addTotalQuestionsFromFrameworksData(vendorFrameworks: any[], frameworksData: any): void {
    // Iterate over vendorFrameworks array
    vendorFrameworks.forEach((framework: any) => {
      // Iterate through frameworksData domains
      for (const domain in frameworksData) {
        if (Object.hasOwn(frameworksData, domain)) {
          const domainData = frameworksData[domain];

          // Check in VENDOR_FRAMEWORKS
          const vendorFrameworksArray = domainData.VENDOR_FRAMEWORKS;
          if (Array.isArray(vendorFrameworksArray)) {
            const matchedFramework = vendorFrameworksArray.find((fw: any) => fw.key === framework.key);

            // If a match is found, add totalQuestions to the current framework object
            if (matchedFramework && matchedFramework.totalQuestions) {
              framework.totalQuestions = parseInt(matchedFramework.totalQuestions, 10) || 0;
              break; // Break as we've found the matching framework
            }
          }
        }
      }
    });
  }

  formatFrameworksByGroup(frameworks) {
    interface Framework {
      key: string;
      name: string;
      status: boolean;
      frameworkFamily?: string;
      custom?: boolean;
      totalQuestions?: number;
    }

    interface GroupedFramework {
      name: string;
      key: string;
      status: boolean;
      children?: Framework[];
      frameworkFamily?: string;
      custom?: boolean;
      totalQuestions?: number;
    }

    const groupedFrameworks: { [key: string]: GroupedFramework } = {};
    const result: GroupedFramework[] = [];

    frameworks.forEach(framework => {
      if (framework.frameworkFamily) {
        // If the framework has a frameworkFamily, group it
        if (!groupedFrameworks[framework.frameworkFamily]) {
          groupedFrameworks[framework.frameworkFamily] = {
            name: framework.frameworkFamily,
            key: framework.frameworkFamily,
            status: false, // initially set to false, will update late
            children: [],
          };
        }

        groupedFrameworks[framework.frameworkFamily].children.push({
          name: framework.name,
          key: framework.key,
          status: framework.status,
          totalQuestions: framework.totalQuestions,
          frameworkFamily: framework.frameworkFamily,
          custom: framework.custom,
        });

        // Update parent status to true if any child has status true
        if (framework.status) {
          groupedFrameworks[framework.frameworkFamily].status = true;
        }
      } else {
        // If the framework has no frameworkFamily, add it directly to the result
        result.push({
          name: framework.name,
          key: framework.key,
          status: framework.status,
          totalQuestions: framework.totalQuestions,
          frameworkFamily: framework.frameworkFamily,
          custom: framework.custom,
        });
      }
    });

    // Convert grouped frameworks into an array and sort children if any
    const groupedArray = Object.values(groupedFrameworks);

    // Combine the individual frameworks and grouped frameworks, and sort by name
    return result.concat(groupedArray).sort((a, b) => a.name.localeCompare(b.name));
  }

  async changeFrameWork(frameworkKey: string = '', isFirstTime: boolean = false): Promise<void> {
    const frameworkFile = await this.getFrameworkFromS3(frameworkKey);
    this.parsedCSVData = this.parseCSV(frameworkFile);

    const domains = [];
    this.parsedCSVData?.forEach(data => {
      if (!domains.includes(data?.category)) {
        domains.push(data?.category);
      }
    });

    if (!isFirstTime && frameworkKey !== 'ENBD_VENDOR_RISK') {
      this.cachedDomain = domains;
      this.enbdDomainList = this.cachedDomain;

      this.enbdDomainList?.map(domain => {
        return domain?.toLowerCase();
      });
      this.cachedDomain = this.cachedDomain.map((str, index) => {
        return {
          name: str,
          id: index + 1,
          isQualify: this.enbdDomainList?.includes(str?.toLowerCase()) ? true : false,
          defaultSelected: this.enbdDomainList?.includes(str?.toLowerCase()) ? true : false,
        };
      });
    } else {
      this.enbdDomainList =
        this.isBulkUpload || this.isFromDefaultSettings ? this.enbdDomainList : this.defaultSelectedDomains;
    }

    this.allDomainList = domains;
    // this.cachedDomain = this.enbdDomainList; //check again
  }

  async getFrameworkFromS3(frameworkKey: string): Promise<any> {
    try {
      const filePath = `FRAMEWORKS/${frameworkKey}.csv`;
      return this.fileService.getTextFileFromS3(filePath, { download: true, level: 'public' });
    } catch (err) {
      console.log('ERR: getFrameworkFromS3 - :', err);
      return null;
    }
  }

  parseCSV(csv) {
    const parsedData = [];

    Papa.parse(csv, {
      header: true,
      step: (results, parser) => {
        if (results && results.errors && !!results.errors.length) {
          if (results.errors.find(e => e.code?.toLowerCase() === 'toofewfields')) {
            return false;
          }
        }

        if (results && results.data && !!Object.keys(results.data).length) {
          const headerKeys = Object.keys(results.data);
          const requiredHeadersExist = this.requiredHeaders.every(key =>
            headerKeys.some(k => key.toLowerCase() === k.toLowerCase())
          );
          if (requiredHeadersExist) {
            if (!Object.values(results.data).join('').trim().length) {
              return false;
            }
            parsedData.push({ ...(results.data as any) });
          } else {
            parser.abort();
          }
        }
      },
    });

    return parsedData;
  }

  openDomainPopUp(): void {
    const modalComponent = EnbdDomainPopupComponent;
    this.modalRef = this.modalService.open(modalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'advanced-impact-popup-window',
      backdropClass: 'new-vendor-popup-backdrop-modal',
    });
    const compInstance = this.modalRef.componentInstance;
    compInstance.domainList = this.allDomainList;
    const enbdDomainList = [];
    this.enbdDomainList?.forEach(domain => {
      enbdDomainList.push(domain.name ? domain.name : domain);
    });

    let selectedDomains = this.defaultSelectedDomains.length
      ? [...this.selectedDomains, ...this.defaultSelectedDomains]
      : [...this.selectedDomains, ...enbdDomainList];

    selectedDomains = [...new Set(selectedDomains)];

    compInstance.selectedDomains = selectedDomains;

    this.modalRef.componentInstance.domain.subscribe((domainList: any) => {
      this.enbdDomainList = domainList?.filter(domain => {
        return domain?.isQualify;
      });
      this.selectedDomains = [];
      this.enbdDomainList?.forEach(domain => {
        if (domain.isQualify) {
          this.selectedDomains.push(domain.name);
        }
      });
      this.assessmentData.enbdDomains = this.selectedDomains;

      this.assessmentDataChange.emit(this.assessmentData);

      this.modalRef.close();
    });
    this.modalRef.componentInstance.closeModal.subscribe(() => {
      this.modalRef.close();
    });
  }
  updateSelectedLevel(): void {}
  /**
   * Setting the startingDate or deadlineDate in this function
   * @param date The actual NGB Date Struct from calendar
   * @param dateType The Date type , either Starting Date or Deadline Date
   */
  dateChanged(date, dateType: string): void {
    this[dateType] = date;
    if (dateType === this.ASSESSMENT_DATE) {
      this.assessmentData.startDate = date;
    } else {
      this.assessmentData.endDate = date;
    }
    this.assessmentDataChange.emit(this.assessmentData);
  }

  decrementor(): void {
    if (this.assessmentData.frequencyDays > 1) {
      this.assessmentData.frequencyDays = this.assessmentData.frequencyDays - 1;
    }
  }
  decrementorReviewDate(): void {
    if (this.assessmentData.nextFrequencyDate < 1) {
      this.assessmentData.nextFrequencyDate = 1;
      return;
    }

    if (this.assessmentData.nextFrequencyDate > 1) {
      this.assessmentData.nextFrequencyDate = this.assessmentData.nextFrequencyDate - 1;
    }
  }

  incrementorReviewDate() {
    this.assessmentData.nextFrequencyDate = this.assessmentData.nextFrequencyDate + 1;
    if (this.assessmentData.nextFrequencyDate > this.assessmentData.frequencyDays) {
      this.assessmentData.nextFrequencyDate = this.assessmentData.frequencyDays;
    }
  }
  incrementorFrequencyDate() {
    this.assessmentData.frequencyDays = this.assessmentData.frequencyDays + 1;
    if (this.assessmentData.frequencyDays > this.maxFrequencyDays) {
      this.assessmentData.frequencyDays = this.maxFrequencyDays;
    }
  }

  focusOutHandler() {
    if (this.assessmentData.nextFrequencyDate > this.assessmentData.frequencyDays) {
      this.assessmentData.nextFrequencyDate = this.assessmentData.frequencyDays - 1;
    } else {
      if (this.assessmentData.nextFrequencyDate < 1) {
        this.assessmentData.nextFrequencyDate = 1;
      }
    }
  }
  focusOutHandlerInput() {
    if (this.assessmentData.frequencyDays > this.maxFrequencyDays) {
      this.assessmentData.frequencyDays = this.maxFrequencyDays;
    } else {
      // if (this.assessmentData.frequencyDays < 1) {
      //   this.assessmentData.frequencyDays = 1;
      // }
    }
  }
  focusOutHandlerAssessmnetInput() {
    if (this.assessmentData.assessmentDays > this.maxFrequencyDays) {
      this.assessmentData.assessmentDays = this.maxFrequencyDays;
    }
    // else {
    //   if (this.assessmentData.assessmentDays < 1) {
    //     this.assessmentData.assessmentDays = 1;
    //   }
    // }
  }
  selectFrequency(event): any {
    this.showAdvanceSettings = event?.toLowerCase() === 'advance';
    this.assessmentData.frequencyName = event;
    this.assessmentDataChange.emit(this.assessmentData);
  }

  updateAssessmentData(type: string, frameworkObj: any, updateLevel = false): void {
    if (type === 'framework') {
      const orderIndex = {};
      this.levelOptionsOrder.forEach((opt, index) => {
        orderIndex[opt] = index;
      });
      frameworkObj.level = frameworkObj.level.sort((a, b) => {
        return (orderIndex[a] ?? Infinity) - (orderIndex[b] ?? Infinity);
      });

      if (frameworkObj) {
        if (frameworkObj.children && frameworkObj.children.length > 0) {
          // Map levels to totalQuestions from children
          this.levelOptions = frameworkObj.level.map((level: string) => {
            const child = frameworkObj.children.find((childr: any) => childr.name.includes(level));
            return {
              level,
              totalQuestions: child?.totalQuestions || 0, // Use child's totalQuestions or default to 0
            };
          });
        } else if (frameworkObj.level && frameworkObj.level.length > 0) {
          // Use totalQuestions from the main framework if no children
          this.levelOptions = frameworkObj.level.map((level: string) => ({
            level,
            totalQuestions: frameworkObj.totalQuestions || 0, // Default to 0
          }));
        } else {
          // No children or levels
          console.error('Framework does not have children or levels');
          this.levelOptions = [];
        }
      } else {
        console.error('Framework object is undefined or null');
        this.levelOptions = [];
      }

      this.assessmentData.level = updateLevel ? null : this.assessmentData.level;
      this.assessmentData.level = this.assessmentData.level
        ? this.assessmentData.level.toUpperCase()
        : this.levelOptions[0]?.level?.toUpperCase();
      this.assessmentData.frameworkName = frameworkObj.name.toUpperCase();
      this.assessmentData.frameworkKey = frameworkObj.key;
      this.tpService?.addPreviousAssessment(this.assessmentData);
      this.assessmentData[type] = frameworkObj;
      this.assessmentDataChange.emit(this.assessmentData);
      if (this.isENBD) {
        this.isEnbdVendorRisk = this.assessmentData?.frameworkKey === 'VENDOR_RISK_ENBD';
      }

      return;
    }

    if (type === 'level') {
      this.assessmentData.level = frameworkObj.toUpperCase();
      this.assessmentDataChange.emit(this.assessmentData);
    }
    if (type === 'artifactOption') {
      this.assessmentData.artifactOption = frameworkObj;
    }
    if (type === 'commentOption') {
      this.assessmentData.commentOption = frameworkObj;
    }
    this.tpService?.addPreviousAssessment(this.assessmentData);
    this.assessmentDataChange.emit(this.assessmentData);
  }

  prioritizeRisks(riskList): any {
    const arrayMove = (arr, oldIndex, newIndex) => {
      if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
      return arr; // for testing
    };

    const findIndexInList = (arr, key, value): number => {
      return arr.findIndex(element => {
        if (element[key] === value) {
          return true;
          // eslint-disable-next-line no-prototype-builtins
        } else if (element.hasOwnProperty('children')) {
          return findIndexInList(element.children, key, value);
        } else {
          return false;
        }
      });
    };

    let index: number;
    switch (true) {
      case UtilsService.isENBD:
        index = findIndexInList(riskList, 'key', this.VendorRiskFrameworkEnum.VENDOR_RISK_ENBD);
        arrayMove(riskList, index, 0); // move to the top the array
        break;
      case UtilsService.isAdnoc:
        index = findIndexInList(riskList, 'key', this.VendorRiskFrameworkEnum.VENDOR_RISK_PRESAQ);
        arrayMove(riskList, index, 0); // move to the top the array
        index = findIndexInList(riskList, 'key', this.VendorRiskFrameworkEnum.VENDOR_RISK_PRESAQ_V2);
        arrayMove(riskList, index, 1); // move VENDOR_RISK_PRESAQ_V2 framework as second
        index = findIndexInList(riskList, 'key', this.VendorRiskFrameworkEnum.VENDOR_RISK_SAQ);
        arrayMove(riskList, index, 2); // move VENDOR_RISK_SAQ framework as third
        index = findIndexInList(riskList, 'key', this.VendorRiskFrameworkEnum.VENDOR_RISK_SAQ_V2);
        arrayMove(riskList, index, 3); // move VENDOR_RISK_SAQ_V2 framework as forth
        break;
      case UtilsService.isInformatica || UtilsService.isInformaticaUat:
        index = findIndexInList(riskList, 'key', this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_1);
        arrayMove(riskList, index, 0); // move to the top the array
        index = findIndexInList(riskList, 'key', this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_2);
        arrayMove(riskList, index, 1); // move VENDOR_RISK_INFORMATICA_2 framework as second
        index = findIndexInList(riskList, 'key', this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_3);
        arrayMove(riskList, index, 2); // move VENDOR_RISK_INFORMATICA_3 framework as third
        index = findIndexInList(riskList, 'key', this.VendorRiskFrameworkEnum.VENDOR_RISK_OSAE);
        arrayMove(riskList, index, 3); // move VENDOR_RISK_OSAE framework as forth
        break;
      case UtilsService.isMoelis:
        index = findIndexInList(riskList, 'key', this.VendorRiskFrameworkEnum.VENDOR_RISK_MOELIS_M1);
        arrayMove(riskList, index, 0); // move to the top the array
        break;
      default:
        index = findIndexInList(riskList, 'key', this.VendorRiskFrameworkEnum.VENDOR_RISK);
        arrayMove(riskList, index, 0); // move to the top the array
        break;
    }
    return riskList;
  }

  /**
   * this function will update the values of auto reminder and auto re-assessment
   * @param checkboxOpt an object having name of option and boolean to tell if its selected or not
   * @return void
   */
  checkboxSelection(checkboxOpt: any): void {
    checkboxOpt.checked = !checkboxOpt?.checked;
    this.assessmentData.isAutoReassessment = checkboxOpt.checked;
    this.assessmentDataChange.emit(this.assessmentData);
  }
  get minimumEndDate() {
    const startDateObj = new Date();
    startDateObj.setDate(startDateObj.getDate() + 1);
    return UtilsService.getDateInNgbDateStructFormat(startDateObj);
  }
}
