import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  TemplateRef,
  Input,
  ElementRef,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EntityTypeEnum, FileTypeEnum, GetEntityQuery, StandardType } from 'app/API.service';
import { FileService } from 'app/shared/file.service';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { EntityService } from 'app/shared/entity.service';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { ClientLambdaService } from 'app/shared/client-lambda.service';
import { AdditionalRequirements } from 'app/shared/enums/common.enum';
import { ThirdPartyService } from '../third-party.service';
import { FrameworkEnumTypes } from 'app/shared/enums/framework-enum-types.enum';
import { VendorNotifyTypeEnum } from 'app/shared/enums/email-notifications.enum';
import { ModalTemplateComponent } from 'app/shared/modal-template/modal-template.component';
import { DomainFrameworkService } from 'app/shared/domain-framework.service';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'cygov-default-settings-new-modal',
  templateUrl: './default-settings-new-modal.component.html',
  styleUrls: ['./default-settings-new-modal.component.scss'],
})
export class DefaultSettingsNewModalComponent implements OnInit, AfterViewInit {
  @Output() closeDefaultModal = new EventEmitter<boolean>();
  @Input() entityId: any;
  @Input() isFromGeneralSettings: boolean = false;
  activeTabId = 1;
  NotifyMessages: any = [];

  daysCount: number = 1;
  assessmentCompleteDays: number = 1;
  assessmentOverdueDays: number = 1;
  vendorsCount: number = 50;
  currentUser;

  sliderScore: number = 30;
  gradient = ['#F77057', '#C71B40']; // slider background gradient
  riskGradient = ['#6A11C3', '#340E60'];

  selectedOpt = '';

  entity: GetEntityQuery = null;

  assessmentData: any = {
    frameworkName: null,
    frameworkKey: null,
    level: '',
    startDate: null,
    endDate: null,
    frequencyDays: 365,
    assessmentDays: 30,
    frequencyName: null,
    artifactOption: 'suggested',
    commentOption: 'suggested',
    enbdDomains: [],
    isAutoReassessment: true,
  };
  additionalData: any = {
    requirements: [],
    certifications: [],
    isThirdParty: false,
    isFourthParty: false,
    domains: [],
    customRequirements: [],
    customCertifications: [],
  };
  customRequirements: any = [];
  customCertifications: any = [];
  customDefaultTypes = {
    SCANS: [],
    DOCUMENTS: [],
    OTHERS: [],
    CERTIFICATIONS: [],
  };
  loading = true;
  VendorRiskFrameworkEnum: any;
  isHowden = UtilsService.isHowden;
  isENBD: boolean = UtilsService.isENBD;
  closeModalInstance: any;
  ngbModalOptionsVendorPackage: NgbModalOptions = {
    size: 'sm',
    // modal-template-cont, modal-template-cont-backdrop must classes for standard design of popup
    windowClass: 'modal-template-cont confirmation-filter-popup-window vendor-package-modal-window',
    backdropClass: 'modal-template-cont-backdrop risk-popup-backdrop-modal vendor-package-modal-template-backdrop',
    backdrop: 'static',
  };
  ngbModalOptionsNewFrameworkRequest: NgbModalOptions = {
    size: 'sm',
    // modal-template-cont, modal-template-cont-backdrop must classes for standard design of popup
    windowClass:
      'modal-template-cont confirmation-filter-popup-window new-framework-request-acknowledgement-modal-window',
    backdropClass:
      'modal-template-cont-backdrop risk-popup-backdrop-modal new-framework-acknowledgement-request-modal-backdrop',
    backdrop: 'static',
    centered: true,
  };
  isInformatica = UtilsService.isInformatica || UtilsService.isInformaticaUat;

  constructor(
    public activeModal: NgbActiveModal,
    private fileService: FileService,
    private route: ActivatedRoute,
    private entityService: EntityService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private clientLambdaService: ClientLambdaService,
    private thirdPartyService: ThirdPartyService,
    private modalService: NgbModal,
    private userService: UserService,
    private elRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.VendorRiskFrameworkEnum = this.fileService.importFrameworkEnumsFromS3(
      FrameworkEnumTypes.VENDOR_RISK_FRAMEWORK_ENUM
    );
  }

  async ngOnInit() {
    if (this.isFromGeneralSettings && this.loading) {
      const modalEl = this.elRef.nativeElement.closest('.modal-content');
      this.renderer.setStyle(modalEl, 'background-color', 'transparent');
    }
    // getting the entity of the current root entity.
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId') || this.entityId;
    this.entity = await this.entityService.getEntity(entityId);
    this.currentUser = await this.userService.getCurrentUser();

    // updating the data of the additional requirements. and risk framework (assessment)
    if (this.entity) {
      this.additionalData = {
        requirements: this.entity.defaultSetting.artifacts,
        certifications: this.entity.defaultSetting.standards,
        isThirdParty: this.entity.defaultSetting.isThirdParty ?? true,
        isFourthParty: this.entity.defaultSetting.isFourthParty ?? false,
        domains: this.entity?.defaultSetting?.domains?.length ? JSON.parse(this.entity.defaultSetting.domains) : [],
        customRequirements: this.entity.defaultSetting?.customRequirements
          ? this.entity.defaultSetting?.customRequirements
          : [],
        customCertifications: this.entity.defaultSetting?.customCertifications
          ? this.entity.defaultSetting?.customCertifications
          : [],
      };
      const parsedOtherData = this.entity?.defaultSetting?.otherData
        ? JSON.parse(this.entity?.defaultSetting?.otherData)
        : null;
      const selectedRiskName = this.entity?.defaultSetting?.selectedRisk
        ? this.entity?.defaultSetting.selectedRisk.toUpperCase().replace(' FULL', '')
        : this.getDefaultFrameworkName().toUpperCase().replace(' FULL', '');
      this.assessmentData = {
        frameworkName: selectedRiskName.toUpperCase(),
        frameworkKey: UtilsService.getEnumKey(this.VendorRiskFrameworkEnum, selectedRiskName),
        level: this.entity.defaultSetting.selectedLevel
          ? this.entity.defaultSetting.selectedLevel.toUpperCase()
          : 'FULL',
        startDate: parsedOtherData?.startDate ? parsedOtherData.startDate : null,
        endDate: parsedOtherData?.endDate ? parsedOtherData.endDate : null,
        frequencyDays: parsedOtherData?.frequencyDays ? this.entity.defaultSetting.frequencyDays : 365,
        assessmentDays: parsedOtherData?.assessmentDays ? this.entity.defaultSetting.assessmentDays : 30,
        artifactOption: parsedOtherData?.artifactOption ? parsedOtherData.artifactOption : 'suggested',
        commentOption: parsedOtherData?.commentOption ? parsedOtherData.commentOption : 'suggested',
        frequencyName: this.entity?.defaultSetting?.frequency ? this.entity.defaultSetting.frequency : null,
        isAutoReassessment: this.entity?.defaultSetting?.isAutoReassessment ?? true,
      };
      this.entity.defaultSetting.fourthPartyWeightage = this.entity?.defaultSetting?.fourthPartyWeightage
        ? this.entity?.defaultSetting?.fourthPartyWeightage
        : 50;
      this.sliderScore = this.entity.defaultSetting.fourthPartyWeightage;
    }

    this.initializeDefaultSettingsData();
    this.loading = false;
  }

  async ngAfterViewInit() {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId') || this.entityId;
    this.entity = await this.entityService.getEntity(entityId);
  }

  getDefaultFrameworkName(): string {
    let defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK;
    switch (true) {
      case UtilsService.isITC:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_ITC;
        break;
      case UtilsService.isHowden:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_HOWDEN_FULL;
        break;
      case UtilsService.isCRB:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_CRB;
        break;
      case UtilsService.isSSMHealth:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_SSM_HEALTH;
        break;
      case UtilsService.isENBD:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_ENBD;
        break;
      case UtilsService.isAdnoc:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_PRESAQ;
        break;
      case UtilsService.isInformatica || UtilsService.isInformaticaUat:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_1;
        break;
      case UtilsService.isMoelis:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_MOELIS_M1;
        break;
      // case UtilsService.isDBU:
      //   defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_DBU_HECVAT_LITE;
      //   break;
    }
    return defaultFrameworkName;
  }

  assessmentDataChange(event: any): void {
    let obj = null;
    if (event && this.entity) {
      obj = {
        level: event?.level,
        startDate: event?.startDate,
        endDate: event?.endDate,
        frequencyDays: event?.frequencyDays,
        assessmentDays: event?.assessmentDays,
        artifactOption: event?.artifactOption,
        commentOption: event?.commentOption,
      };
      this.entity.defaultSetting.selectedRisk = event?.frameworkName.toUpperCase();
      this.entity.defaultSetting.selectedLevel = event.level.toUpperCase();
      this.entity.defaultSetting.isAutoReassessment = event.isAutoReasessment;
      // this.entity.defaultSetting.selectedLevel=event?.
      this.entity.defaultSetting.frequencyDays = event.frequencyDays;
      this.entity.defaultSetting.assessmentDays = event.assessmentDays;
      this.entity.defaultSetting.frequency = event?.frequencyName;
      this.entity.defaultSetting.otherData = JSON.stringify(obj);
      if (this.isENBD) {
        this.entity.defaultSetting.enbdDomain = event?.enbdDomains;
      }
    }
  }
  updateCertifications(event: any): void {
    if (this.entity && event?.data?.length && event.type === AdditionalRequirements.CERTIFICATIONS) {
      this.entity.defaultSetting.standards = event?.data;
    } else if (this.entity && event?.data?.length && event.type === AdditionalRequirements.REQUIREMENTS) {
      this.entity.defaultSetting.artifacts = event?.data;
    } else if (this.entity && event?.data?.length && event.type === AdditionalRequirements.CUSTOM_REQUIREMENTS) {
      this.customRequirements = event.data;
    } else if (this.entity && event?.data?.length && event.type === AdditionalRequirements.CUSTOM_CERTIFICATIONS) {
      this.customCertifications = event.data;
    } else if (this.entity && (event.type === 'isThirdParty' || event.type === 'isFourthParty')) {
      this.entity.defaultSetting[event.type] = event?.data;
    } else if (this.entity && event.type === 'domains') {
      const finalArr = [];
      event.data.forEach(data => {
        finalArr.push({ value: data });
      });
      this.entity.defaultSetting.domains = JSON.stringify(finalArr);
    }
  }

  async saveDefaultSetting(): Promise<void> {
    try {
      if (this.assessmentData?.frequencyDays < 1) {
        this.toastr.error('Invalid re-asess days');
        return;
      }
      if (this.assessmentData?.frequencyDays <= this.assessmentData.assessmentDays) {
        // eslint-disable-next-line quotes
        this.toastr.error("Re-assess days can't be less than assessment days");
        return;
      }
      this.loading = true;
      // we need to check if there are any customFrameworks are added or not.
      if (this.customRequirements?.length) {
        await this.uploadFilesAndFrameworks(AdditionalRequirements.CUSTOM_REQUIREMENTS);
      }
      if (this.customCertifications?.length) {
        await this.uploadFilesAndFrameworks(AdditionalRequirements.CUSTOM_CERTIFICATIONS);
      }
      // remove extra spaces from the custom days.
      // this.addRemoveExtrasCustomDay();

      this.entity.defaultSetting.frequencyDays = this.assessmentData.frequencyDays;
      this.entity.defaultSetting.assessmentDays = this.assessmentData.assessmentDays;
      this.entity.defaultSetting.selectedLevel = this.assessmentData.level.toUpperCase();
      this.entity.defaultSetting.isAutoReassessment = this.assessmentData.isAutoReassessment;
      // this.entity.defaultSetting.selectedLevel = this.entity.defaultSetting.surveyLevel =
      this.entity?.defaultSetting?.surveyLevel?.toUpperCase()?.replaceAll(' ', '_');
      this.NotifyMessages.forEach(message => {
        // Check if `fixedDays` exists and iterate over it
        if (message.fixedDays) {
          // Update `custom` property to false if true
          message.fixedDays.forEach(day => {
            if (day.custom === true) {
              day.custom = false;
            }
          });

          // Sort `fixedDays` for specific notification types
          if (message.notificationType === VendorNotifyTypeEnum.REMIND_VENDOR_ASSESSMENT_OVERDUE) {
            message.fixedDays.sort((a, b) => a.value - b.value); // Ascending
          } else if (message.notificationType === VendorNotifyTypeEnum.REMIND_VENDOR_TO_COMPLETE_ASSESSMENT) {
            message.fixedDays.sort((a, b) => b.value - a.value); // Descending
          }
        }
      });

      this.entity.defaultSetting.notifications = JSON.stringify(this.NotifyMessages);
      this.entity = JSON.parse(
        JSON.stringify(this.entity, (k, v) =>
          k === '__typename' ||
          k === 'activeAssessment' ||
          k === 'activeAssessmentId' ||
          k === 'vendorDetails' ||
          k === 'updatedAt'
            ? undefined
            : v
        )
      );
      // * deleting framework related attributes due to these attributes being null
      // * otherwise request throws an error
      if (this.entity && !this.entity.frameworkId) {
        delete this.entity.frameworkId;
      }
      if (this.entity && !this.entity.frameworkName) {
        delete this.entity.frameworkName;
      }
      if (this.entity && !this.entity.frameworkType) {
        delete this.entity.frameworkType;
      }
      if (this.entity?.AI) {
        delete this.entity.AI;
      }

      // check Either the WeightAge is changed or not
      if (this.sliderScore !== this.entity.defaultSetting.fourthPartyWeightage) {
        // Weightage is changed
        this.entity.defaultSetting.fourthPartyWeightage = this.sliderScore;
        // rootEntityId and 4th party weightAge value ( 10 to 50)
        await this.thirdPartyService.applyWeightAgeCalculationsForAllVendors(this.entity.id, this.sliderScore);
      }
      if (this.isENBD) {
        this.assessmentData.enbdDomains = this.entity.defaultSetting?.enbdDomain;
      }
      await this.entityService.updateEntity(this.entity);
      this.entityService.updateEntityMapper(this.entity);

      this.toastr.success('Default setting saved successfully!');
      this.loading = false;
      if (!this.isFromGeneralSettings) {
        this.closeDefaultModal.emit(true);
      }
    } catch (e) {
      console.log('error', e);
      this.logger.error('saveDefaultSetting - Error: ', e);
      this.toastr.error('Failed to save default setting');
      this.loading = false;
      if (!this.isFromGeneralSettings) {
        this.closeDefaultModal.emit(false);
      }
    }
  }
  increment(label: string, customDays: any[], index: number): void {
    if (label === 'VENDORS_COUNT') {
      this.vendorsCount += 50;
    } else {
      const currentDay = customDays[index];
      const maxValue = label === 'notify2' ? 30 : 7;

      if (currentDay.value < maxValue) {
        let newValue = currentDay.value + 1;

        // Find the next available value that doesn't create a duplicate
        while (customDays.some(day => day.value === newValue) && newValue <= maxValue) {
          newValue++;
        }

        if (newValue <= maxValue) {
          currentDay.value = newValue; // Set to the next valid value
        } else {
          this.toastr.warning(`Days duplication is not allowed. ${newValue - 1} days already added in the list.`);
        }
      }
    }
  }

  decrement(label: string, customDays: any[], index: number): void {
    if (label === 'VENDORS_COUNT') {
      this.vendorsCount -= 50;
    } else {
      const currentDay = customDays[index];
      const minValue = 1; // Minimum allowed value for decrement

      if (currentDay.value > minValue) {
        let newValue = currentDay.value - 1;

        // Find the next available value that doesn't create a duplicate
        while (customDays.some(day => day.value === newValue) && newValue >= minValue) {
          newValue--;
        }

        if (newValue >= minValue) {
          currentDay.value = newValue; // Set to the next valid value
        } else {
          this.toastr.warning('No unique value available for decrementing without duplication of Days');
        }
      }
    }
  }

  // sortCustomDays(label: string, customDays: any[]): void {
  //   if (label === 'notify2') {
  //     // Sort descending for ASSESSMENT_COMPLETE_COUNT
  //     customDays.sort((a, b) => b.value - a.value);
  //   } else if (label === 'notify3') {
  //     // Sort ascending for ASSESSMENT_OVERDUE_COUNT
  //     customDays.sort((a, b) => a.value - b.value);
  //   }
  // }

  /**
   * helper function to iterate over the custom requirements/certifications to upload the templates.
   */
  async uploadTemplates(type: string): Promise<any> {
    try {
      const payLoad = {
        creationLevel: 'root_entity',
        rootEntityId: this.entity.id,
        frameworkList: [],
      };
      for (const req of this[type]) {
        if (req.templateURL && !req.templateURL.contentType) {
          req.templateURL = await this.fileService.uploadZippedFiles(
            req.templateURL,
            FileTypeEnum.VENDOR_ARTIFACTS_TEMPLATE
          );
        }
        const alreadyFound = this.entity?.defaultSetting[type]?.find(req1 => req1.name === req.name);
        const startingName =
          type === AdditionalRequirements.CUSTOM_REQUIREMENTS ? StandardType.ARTIFACT : EntityTypeEnum.VENDOR;
        const startingType =
          type === AdditionalRequirements.CUSTOM_REQUIREMENTS
            ? StandardType.ARTIFACT
            : StandardType.COMPLIANCE_FRAMEWORK;
        if (!alreadyFound) {
          payLoad.frameworkList.push({
            name: `${startingName}_${req?.name?.replaceAll(' ', '_')}`,
            key: req?.name?.replaceAll(' ', '_'),
            type: startingType,
            documentCategory: req?.documentCategory,
            documentType: req.documentType ? req?.documentType : null,
            templateType:
              type === AdditionalRequirements.CUSTOM_REQUIREMENTS
                ? AdditionalRequirements.REQUIREMENTS
                : AdditionalRequirements.CERTIFICATIONS,
            templateURL: req?.templateURL,
            vendorId: '',
            documentSubType: req.subType ? req?.subType : null,
          });
          if (!this.entity?.defaultSetting[type]) {
            this.entity.defaultSetting[type] = [];
          }
          this.entity?.defaultSetting[type].push({
            name: req?.name,
            isQualify: req?.isQualify,
            templateURL: req.templateURL ? req.templateURL : null,
            __typename: 'Qualification',
            documentCategory: req?.documentCategory,
            documentType: req?.documentType,
          });
        }
      }
      return payLoad;
    } catch (e) {
      console.log('Cannot upload the template of custom framework to the S3');
      return Promise.reject();
    }
  }

  /**
   *
   */
  async uploadFilesAndFrameworks(type: string): Promise<void> {
    try {
      // 1- First getting all the files. and uploading them to S3.
      const functionName = 'createFrameworkWithRanges';
      const payLoad = await this.uploadTemplates(type);
      // 2- after uploading the files, need to invoke the lambda for creating the custom framework.
      if (payLoad.frameworkList.length) {
        await this.clientLambdaService.invokeLambda(functionName, payLoad);
      } else {
        this.entity.defaultSetting[type] = this[type];
      }
    } catch (e) {
      console.log('Cannot upload the files/frameworks to S3');
      return Promise.reject();
    }
  }

  /**
   * initializing the wizard data change
   * @param
   */
  initializeDefaultSettingsData(): void {
    if (this.entity?.defaultSetting?.notifications) {
      // if entity data is present then add the data into the.
      const parsedNotifications = JSON.parse(this.entity?.defaultSetting?.notifications);
      this.NotifyMessages = parsedNotifications;
      // this.addRemoveExtrasCustomDay(false);
    } else {
      this.NotifyMessages = [
        {
          id: 'notify1',
          key: 'message1',
          description: 'Notify internal project manager when a new user has been added',
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.NOTIFY_MANAGER_NEW_VENDOR_USER,
        },
        {
          id: 'notify2',
          key: 'message2',
          description: `Remind ${this.isHowden ? 'client' : 'vendor'} to complete assessment prior to deadline`,
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.REMIND_VENDOR_TO_COMPLETE_ASSESSMENT,
          allowCustomDay: true, // Allow Custom days to reminder
          fixedDays: [
            { id: 'notify2#fixDay1', selected: true, value: 30, custom: false },
            { id: 'notify2#fixDay2', selected: true, value: 15, custom: false },
            { id: 'notify2#fixDay3', selected: true, value: 7, custom: false },
            { id: 'notify2#fixDay4', selected: true, value: 3, custom: false },
            { id: 'notify2#fixDay5', selected: true, value: 1, custom: false },
          ],
          // customDays: [],
        },
        {
          id: 'notify3',
          key: 'message3',
          description: `Notify ${this.isHowden ? 'client' : 'vendor'} when an assessment is overdue after the deadline`,
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.REMIND_VENDOR_ASSESSMENT_OVERDUE,
          allowCustomDay: true,
          fixedDays: [
            { id: 'notify3#fixDay1', selected: true, value: 1 },
            { id: 'notify3#fixDay2', selected: true, value: 3 },
            { id: 'notify3#fixDay3', selected: false, value: 7 },
          ],
          // customDays: [],
        },
        {
          id: 'notify7',
          key: 'message7',
          description: `Notify internal project manager when an automated reassessment of ${
            this.isHowden ? 'client' : 'vendor'
          } has begun`,
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.NOTIFY_MANAGER_VENDOR_REASSESSMENT,
        },
        {
          id: 'notify4',
          key: 'message4',
          description: 'Notify internal project manager when a new intelligence finding was detected:',
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.NOTIFY_MANAGER_VENDOR_NEW_FINDING,
          isFindings: true,
          findings: [
            { id: 'notify4#finding1', label: 'Any-Finding', selected: false },
            { id: 'notify4#finding2', label: 'Once a Day', selected: true },
          ],
        },
        {
          id: 'notify5',
          key: 'message5',
          description: 'Notify internal project manager when an assessment is completed and pending approval',
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.NOTIFY_MANAGER_ASSESSMENT_COMPLETED,
        },
        {
          id: 'notify6',
          key: 'message6',
          description: `Notify internal project manager when a ${
            // eslint-disable-next-line quotes
            this.isHowden ? "client's" : "vendor's"
          } risk score drops below`,
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.NOTIFY_MANAGER_VENDOR_RISK_DROPPED,
          riskSlider: true,
          riskScore: 3,
        },
      ];
    }
  }

  /**
   * addOrRemove Extra spaces
   */

  addCustomNotifyDay(notification: any, index: number): void {
    if (this.NotifyMessages.find(notify => notify.id === notification.id)) {
      this.NotifyMessages[index].fixedDays.unshift({
        id: notification.id + '#' + notification.fixedDays.length,
        selected: true,
        value: 0,
        custom: true,
      });
    }
  }
  removeCustomDay(notification: any, dayId: string): void {
    const index = this.NotifyMessages.findIndex(notify => notify.id === notification.id);
    if (index >= 0) {
      const dayIndex = this.NotifyMessages[index].fixedDays.findIndex(day => day.id === dayId);
      if (dayIndex >= 0) {
        this.NotifyMessages[index].fixedDays.splice(dayIndex, 1);
      }
    }
  }

  removeFunctionalityCheck(notification) {
    const isCustomDayPresent = notification.fixedDays.some(day => day.custom);

    if (notification.fixedDays.length === 2 && isCustomDayPresent) {
      return false;
    } else if (notification.fixedDays.length === 1 && !isCustomDayPresent) {
      return false;
    }
    return true;
  }

  updateSelectedSurvey(): void {}
  openVendorPackageModal(header: TemplateRef<any>, content: TemplateRef<any>, footer: TemplateRef<any>) {
    const modalRef = this.modalService.open(ModalTemplateComponent, this.ngbModalOptionsVendorPackage);
    const compInstance = modalRef.componentInstance;
    this.closeModalInstance = modalRef;
    console.log(this.vendorsCount);
    compInstance.customizeHeader = true;
    compInstance.headerContentRef = header;
    compInstance.contentRef = content;
    compInstance.footerContentRef = footer;
  }

  openVendorPackageAcknowledgementModal(header: TemplateRef<any>, content: TemplateRef<any>, footer: TemplateRef<any>) {
    this.closeModalTemplate();
    setTimeout(() => {
      const elements = document.querySelectorAll('.fade-in-package');
      elements.forEach(el => el.classList.add('show'));
    }, 0);
    const modalRef = this.modalService.open(ModalTemplateComponent, this.ngbModalOptionsNewFrameworkRequest);
    const compInstance = modalRef.componentInstance;
    this.closeModalInstance = modalRef;
    console.log('this.vendor', this.vendorsCount);

    compInstance.customizeHeader = true;
    compInstance.headerContentRef = header;
    compInstance.contentRef = content;
    compInstance.footerContentRef = footer;
  }
  async sendData() {
    const emailObject = {
      type: 'NOTIFY_CENTRALEYES_ADD_VENDOR_REQUEST',
      vendorCount: this.vendorsCount,
      name: this.currentUser.name,
      entity: this.entity.name,
      email: this.currentUser.email,
      domain: DomainFrameworkService.getDomain(),
    };
    this.vendorsCount = 50;
    await this.clientLambdaService.invokeLambda('sendNotification', emailObject);
  }
  closeModalTemplate() {
    this.closeModalInstance.close();
  }
}
