import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { UtilsService } from 'app/shared/utils.service';
import JSZip from 'jszip';
import {
  RoleEnum,
  S3FileInput,
  FileTypeEnum,
  GetAnswerQuery,
  GetAssignmentQuery,
  RiskAction,
  CreateCommentInput,
  UpdateCommentInput,
  ExportSourceEnum,
  GetCommentQuery,
  GetRoleQuery,
  APIService,
} from 'app/API.service';
import { FileService } from '../../file.service';
import { NGXLogger } from 'ngx-logger';
import { Question } from 'models/questionnaire.model';
import { QuestionnaireService } from 'app/questionnaire/questionnaire.service';
import { RiskRegisteryEnum } from '../../enums/risk-registery.enum';
import { CommentActionEnum } from '../../enums/question-card.enum';
import { AnswerEnum, BnbAnswerEnum } from '../../enums/answer.enum';
import { v4 as uuid } from 'uuid';
import { CapitalizationRulePipe } from 'app/shared/pipes/capitalization-rule.pipe';
import { SunburstService } from 'app/break-down/sunburst.service';
import { SecurityControlDetailsService } from 'app/shared/security-control-details/security-control-details.service';
import { Subscription } from 'rxjs';
import { RemediationService } from 'app/remediation/remediation.service';
import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';
import { UserService } from 'app/shared/user.service';
import { AppLevelRoleEnum } from 'app/shared/enums/appLevelRoles.enum';
import { CollectionService } from 'app/collection/collection.service';
import { LogsKeyEnum, LogsTypeEnum } from 'app/shared/enums/logsKey.enum';
import { FrameworkEnumTypes } from 'app/shared/enums/framework-enum-types.enum';
import { DomainFrameworkService } from 'app/shared/domain-framework.service';
import { ManagerActionEnum } from 'app/shared/enums/manager-action.enum';
import { trigger } from '@angular/animations';
import { ArrowsEnum } from 'app/shared/enums/shared.enum';
import { getLogMessage } from 'app/shared/helpers/logs.helper';
import { EntityService } from 'app/shared/entity.service';
import { CUSTOMAPIService } from 'app/custom-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SVG } from 'app/shared/enums/integration.enum';
import { NA_SCORES } from 'app/collection/collection.constant';
@Component({
  selector: 'cygov-question-card-ui',
  templateUrl: './question-card-ui.component.html',
  styleUrls: ['./question-card-ui.component.scss'],
  providers: [CapitalizationRulePipe],
  animations: [trigger('openingAnimation', [])], // need to add in-case your are using any kinda animations in your code.
})
export class QuestionCardUiComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() practices: any;
  @Input() questionsInfo: any;
  @Input() riskRegistery: RiskRegisteryEnum = RiskRegisteryEnum.Normal;
  @Input() showQuestionWithoutUser: boolean;
  @Input() closeRemediateModal: boolean = false;
  @Input() userId: string;
  @Input() comment = '';
  @Input() isShowCollapseIcon: boolean = false;
  @Input() answersFilter: string[] = [];
  @Input() assignments: GetAssignmentQuery[] = [];
  @Output() save = new EventEmitter<Question[]>();
  @ViewChild('uploadArtifact') uploadInput: ElementRef;

  questions: any = [];
  prevQid: any;
  preI: any;
  answerEnum = AnswerEnum;
  bnbAnswerEnum = BnbAnswerEnum;
  hasPermission = false;
  hasApprovalPermission = false;
  hasInfoSecPermission: boolean = UtilsService.isBnB;
  isBnbRisk: boolean = false;
  isRemediate: boolean = false;
  isDataFetched: boolean = true;
  confirmationMessage = 'Do you really want to delete the selected artifact?';
  selectedUserAnswer: GetAnswerQuery;
  possibleAnswers: string[];
  bnbPossibleAnswer: string[];
  answers = [];
  saving = false;
  changedAnswers: Map<number, GetAnswerQuery> = new Map();
  entityId: string;
  showError: boolean;
  collapsedQuestion: boolean[] = [];
  questionsNewComment: Map<string, string> = new Map<string, string>();
  questionsAuditComment: Map<string, string> = new Map<string, string>();
  currentUser: any;
  newLineCapital = false;
  commentLength = 0;
  isThirdPartyView = false;
  isVendorView: boolean = false;
  langDirectionClass: string;
  isPartialSelected: boolean[] = [];
  sliderScore: number[] = [-1];
  sliderValues: number[] = [0, 2.5, 5, 7.5];
  riskFrameworks: string[] = [];
  isFromVendorOrThirdParty: boolean = false;
  subscription: Subscription[] = [];
  assignedUsers = [];
  currentUserRole: GetRoleQuery; //  Adding currentUserRole which will be removed and replaced with user.role after making connection.
  isHigherRole: boolean = false;
  isCRB: boolean = false;
  auditComments: any[] = [];
  userComments: any[] = [];
  prevAns: any = null; // saving the previous answer so that when file is not uploaded it should be used.
  VendorRiskFrameworkEnum: any;
  remediatedItemsList = [];
  isBeecher: boolean = UtilsService.isBnBCyberSite;
  isBnb: boolean = UtilsService.isBnB;
  currentSelectedAnswer: string = '';
  currentPossibleAnswer: string = '';
  mandatoryText: string = '';
  commentAnswerMapper: any = {};
  maxCharsInLine: number = 100;
  arrowsEnum = ArrowsEnum;
  currentSelectedUserId;
  tempQuestionsAnswers: any = [];
  updatedAnswers: any[] = [];
  isUserItSelf: boolean = false;
  isCommentInProgress: boolean = false;
  cachedAnswers: any[] = [];
  INTEGRATION_SVG = SVG.INTEGRATION;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private remediationService: RemediationService,
    private questionnaireService: QuestionnaireService,
    private fileService: FileService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private securityService: SecurityControlDetailsService,
    public capitalization: CapitalizationRulePipe,
    private sunburstService: SunburstService,
    private userService: UserService,
    private api: APIService,
    private customApi: CUSTOMAPIService,
    private collectionService: CollectionService,
    private domainFrameworkService: DomainFrameworkService,
    private entityService: EntityService,
    private cdr: ChangeDetectorRef,
    public activeModal: NgbActiveModal
  ) {
    this.VendorRiskFrameworkEnum = this.fileService.importFrameworkEnumsFromS3(
      FrameworkEnumTypes.VENDOR_RISK_FRAMEWORK_ENUM
    );
  }

  ngOnChanges(): void {
    if (this.closeRemediateModal) {
      this.currentSelectedAnswer = '';
      this.collectionService.commentAnswerMapper = {};
    }
    if (this.questions && this.questions.length) {
      // Remove Duplicate Assignments
      this.questions.forEach(question => {
        if (question.assignments?.length > 1) {
          question.assignments = this.getUniqueAssignments(question.assignments, true);
        }
      });
      this.buildAnswerList();
      this.questions.map(
        question => (
          (this.questionsNewComment[this.isRemediate ? question.questionIds[0] : question.id] = ''),
          (this.questionsAuditComment[this.isRemediate ? question.questionIds[0] : question.id] = '')
        )
      );
    }
  }

  getUniqueAssignments(assignments: any[], hasUser: boolean): any[] {
    const uniqueAssignments = [];
    assignments.forEach(item => {
      if (
        uniqueAssignments.findIndex(element =>
          hasUser ? element.userId === item?.userId : element.id === item?.id
        ) === -1
      ) {
        uniqueAssignments.push(item);
      }
    });

    return uniqueAssignments;
  }

  questionSplitter(indx: number): void {
    let index = this.isRemediate
      ? this.questions[indx].title.indexOf(' ', this.maxCharsInLine - 18)
      : this.questions[indx].name.indexOf(' ', this.maxCharsInLine - 18);
    if (index > 0) {
      if (index > this.maxCharsInLine - 15) {
        index = this.isRemediate
          ? this.questions[indx].title.indexOf(' ', this.maxCharsInLine - 25)
          : this.questions[indx].name.indexOf(' ', this.maxCharsInLine - 25);
      }
      const question = JSON.parse(
        JSON.stringify(this.isRemediate ? this.questions[indx].title : this.questions[indx].name)
      );
      if (this.isRemediate) {
        this.questions[indx].title = this.questions[indx].title.substring(0, index);
      } else {
        this.questions[indx].name = this.questions[indx].name.substring(0, index);
      }
      this.questions[indx].questionExt = question.substring(index);
      this.questions[indx].showMore = true;
      this.questions[indx].showQuestionExt = false;
    } else {
      this.questions[indx].questionExt = '';
      this.questions[indx].showMore = false;
      this.questions[indx].showQuestionExt = false;
    }
  }
  async ngOnInit(): Promise<void> {
    this.hasPermission = this.collectionService.hasPermission();
    this.isRemediate = !this.router.url.includes('break-down');
    this.isHigherRole = this.ifHigherRoles();
    this.isCRB = UtilsService.isCRB;
    // in case of breakdown screen there is a bit different data flow
    // isDataFetched will help to maintain loader on modal.
    this.isDataFetched = this.isRemediate;
    this.questions = this.isRemediate
      ? await this.remediationService.filteredTasks(this.questionsInfo)
      : await this.remediationService.questionSettingsForBreakDown(this.questionsInfo);
    if (!this.isRemediate) {
      // in case of breakdown screen
      const answers = await this.remediationService.setAnswers(this.questions, !this.isRemediate);
      const comments = await this.remediationService.fetchComments(this.questions[0]);
      const commentsOnSelectedQuestion = comments.length && comments.filter(c => c.questionId === this.questions[0].id);
      this.questions[0].answers = answers && answers?.length ? answers[0] : [];
      this.questions[0].comments =
        commentsOnSelectedQuestion && commentsOnSelectedQuestion?.length ? commentsOnSelectedQuestion : [];
    }

    const end = performance.now();

    const start1 = performance.now();
    this.questions.forEach((ques, idx) => {
      this.commentAnswerMapper[ques.id] = {};
      if (this.isRemediate) {
        if (ques.title.length > 100) {
          this.questionSplitter(idx);
        }
      } else {
        if (ques.name.length > 100) {
          this.questionSplitter(idx);
        }
      }
      const frameworkAnswersWithSettings = this.fileService.getFrameworkAnswersOptions(ques.frameworkName);
      ques.frameworkAnswersWithSettings = frameworkAnswersWithSettings;
      ques.possibleAnswers = Object.values(frameworkAnswersWithSettings).map((e: any) => e.name);
    });
    this.buildQuestionsComments();
    this.ngOnChanges();
    this.subscription.push(
      this.securityService.getAssignedUsers().subscribe(users => {
        if (users) {
          // Remove Duplicate Assignments
          users.forEach(user => {
            const tId = user[0]?.taskId;
            if (tId) {
              this.assignedUsers[tId] = user?.length ? this.getUniqueAssignments(user, false) : user;
            }
          });
        }
      })
    );
    this.riskFrameworks = Object.keys(this.VendorRiskFrameworkEnum);
    this.langDirectionClass = this.questions[0].frameworkName.indexOf('ICDM') !== -1 ? 'rtl' : 'ltr';
    // this.possibleAnswers =
    //   this.isBeecher || UtilsService.isHowden
    //     ? Object.values(AnswerEnum).filter(val => val !== AnswerEnum.PARTIAL)
    //     : Object.values(AnswerEnum);
    // this.bnbPossibleAnswer = Object.values(BnbAnswerEnum);
    const totalQuestions = this.questions.length;
    this.collapsedQuestion = Array.from(Array<boolean>(totalQuestions).fill(false));
    this.collapsedQuestion[0] = true;
    this.buildAnswerList();
    this.cachedAnswers = JSON.parse(JSON.stringify(this.answers));

    if (UtilsService.isCRB && this.userService.hasPermissionHierarchy(AppLevelRoleEnum.MSSP)) {
      this.hasApprovalPermission = true;
    }

    this.isThirdPartyView = this.router.url.includes('third-party');
    this.isVendorView = this.router.url.includes('vendor');

    this.entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.currentUser = await this.userService.getCurrentUser();
    this.currentUserRole = this.userService.getCurrentRole();
    this.isFromVendorOrThirdParty =
      this.currentUserRole.name.toLowerCase() === RoleEnum.VENDOR.toLowerCase() ||
      this.isThirdPartyView ||
      this.isVendorView;
    this.sortCommentsAscending();

    this.questions.forEach((question, index) => {
      question.isAverageSelected = !question.settings[0]?.isCollaborative;
      question.isVersionClicked = false;
      question.isSeeAllActivity = false;
      question.showFullComment = false;
      question.isEditComments = false;
      question.isCommentDeleted = false;
      question.logsStartIndex = 0;
      question.logsLastIndex = 2;
      question.questionLogs = question?.logs?.slice(question.logsStartIndex, question.logsLastIndex);
      question.disableUpArrow = true;
      question.disableDownArrow = question?.logs?.length < 3;
      question.commentsToUpdate = [];
      question.commentsToDelete = [];
      question.selectedUserAssignment = null;
      const currentUserRole = this.userService.getCurrentRole();
      question.showAverageAvatar =
        this.isHigherRole ||
        currentUserRole.name.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase() ||
        currentUserRole.name.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase();

      if (!question.settings[0]?.isCollaborative && question.showAverageAvatar) {
        this.showAverageAnswer(question, index);
      }
    });
    this.tempQuestionsAnswers = JSON.parse(JSON.stringify(this.questions));
    this.currentSelectedUserId = this.userId;
    const end1 = performance.now();

    if (this.isRemediate) {
      this.remediationService.fetchLogsAndVersionsForRemediation();
    } else {
      this.remediationService.fetchLogsAndVersionsForBreakdown(this.questions);
    }
  }
  buildQuestionsComments(): void {
    // Fetching comments
    this.questions.map(question => {
      const uComments = question?.comments.filter(c => !c?.type || c?.type === 'user');
      const audComments = question?.comments.filter(c => c?.type === 'audit');
      this.userComments[question.id] = [];
      this.auditComments[question.id] = [];
      this.auditComments[question.id] = audComments.map(c => {
        return {
          id: c.id,
          userId: c.userId,
          userName: c.user.items.length ? c.user.items[0].name : c.archivedUser.name,
          date: c.date,
          comment: c.comment,
          type: c.type ? c.type : null,
        };
      });
      this.userComments[question.id] = uComments.map(c => {
        return {
          id: c.id,
          userId: c.userId,
          userName: c.user.items.length ? c.user.items[0].name : c.archivedUser.name,
          date: c.date,
          comment: c.comment,
          type: c.type ? c.type : null,
        };
      });
      return (
        (this.userComments[question.id] = this.userComments[question.id].sort((a, b) => a.date - b.date)),
        (this.auditComments[question.id] = this.auditComments[question.id].sort((a, b) => a.date - b.date))
      );
    });
  }
  getTotalFiles(index: number): number {
    let s3Inputs = [];
    s3Inputs = this.tempQuestionsAnswers[index].answers
      .map(ans => ans.file)
      .filter(file => file)
      .flat();

    return s3Inputs.length;
  }

  buildAnswerList(): void {
    this.answers = [];
    this.questions.forEach((question, index) => {
      this.answers[index] = {};

      if (UtilsService.isDefined(question?.selectedUserAssignment)) {
        if (question.answers && question.answers.length > 0) {
          question.answers.forEach(answer => {
            if (answer?.user?.id === question?.selectedUserAssignment?.id) {
              this.answers[index] = answer;
            }
          });
        } else if (!!question.managerAnswers && question.managerAnswers.length > 0) {
          question.managerAnswers.forEach(answer => {
            if (answer?.user?.id === question?.selectedUserAssignment?.id) {
              this.answers[index].answer = answer?.answer;
            }
          });
        }
        // sometimes value falls in between in the third party integrations case
        if (this.answers[index]?.answer > 1 && this.answers[index]?.answer < 2.5 && !this.isBnb) {
          this.answers[index].answer = 1;
        }
      } else if (this.userId) {
        if (question.answers && question.answers.length > 0) {
          this.answers[index] = UtilsService.sortByProp(question.answers, 'updatedAt', SortDirectionEnum.DESCENDING)[0];
        } else if (!!question.managerAnswers && question.managerAnswers.length > 0) {
          this.answers[index].answer = question.managerAnswers[0].answer;
        }
        // sometimes value falls in between in the third party integrations case
        if (this.answers && this.answers[index].answer > 1 && this.answers[index].answer < 2.5 && !this.isBnb) {
          this.answers[index].answer = 1;
        }
      } else {
        const allAnswers = question.answers;
        if (allAnswers && allAnswers.length > 0) {
          let averageAnswerValue = allAnswers.reduce((acc, curr) => acc + curr.answer, 0) / allAnswers.length;
          this.answers[index] = UtilsService.sortByProp(allAnswers, 'updatedAt', SortDirectionEnum.DESCENDING)[0];
          // sometimes value falls in between in the third party integrations case
          if (averageAnswerValue > 1 && averageAnswerValue < 2.5) {
            averageAnswerValue = 1;
          }
          this.answers[index].answer = averageAnswerValue;
        }
      }
      this.isPartialSelected[index] = this.answers[index] && this.answers[index].answerName === BnbAnswerEnum.PARTIAL;
      if (this.isPartialSelected[index]) {
        this.sliderScore[index] = this.scoreFlipper(this.answers[index].answer, false);
      }
      if (!this.isRemediate) {
        this.isDataFetched = true;
      }

      this.answers[index] = {
        ...this.answers[index],
        frameworkAnswersWithSettings: question.frameworkAnswersWithSettings,
      };
    });
  }

  isDefined(variable: boolean): boolean {
    return UtilsService.isDefined(variable);
  }

  async onSave(): Promise<void> {
    this.saving = true;
    this.showError = false;
    // Answer will only be saved on the save button
    // this.answers.map(answer => {
    //   const res = this.remediatedItemsList.find(obj => obj.answerId === answer.id);
    //   if (res) {
    //     answer.answer = res.sliderScore
    //       ? this.answerEnumToNum(res.answer, answer.frameworkAnswersWithSettings, this.scoreFlipper(res.sliderScore))
    //       : this.answerEnumToNum(res.answer, answer.frameworkAnswersWithSettings, answer.answer);
    //     answer.answerName = res.answer;
    //   }
    // });

    const updatedQuestionsList: Question[] = [];

    if (!this.allQuestionsValid()) {
      this.saving = false;
      return;
    }

    const chunkSize = 10;
    const totalQuestions = this.questions.length;
    for (let i = 0; i < totalQuestions; i += chunkSize) {
      const chunk = this.questions.slice(i, i + chunkSize);
      const questionPromises = chunk.map(
        (question, index) => this.answerQuestion(updatedQuestionsList)(question, index) // Call the curried function correctly
      );
      await Promise.all(questionPromises);
      const remediatedQuestions = chunk.filter(question => this.isQuestionRemediated(question));
      updatedQuestionsList.push(...remediatedQuestions);
      const percentage = Math.ceil((updatedQuestionsList.length / totalQuestions) * 100);
      console.log(`${updatedQuestionsList.length}/${totalQuestions} questions remediated`);
      this.toastr.info(`${percentage}% Questions remediated successfully`);
      await new Promise(resolve => setTimeout(resolve, 4000));
    }
    if (updatedQuestionsList && !updatedQuestionsList.length) {
      this.saving = false;
      this.save.emit(updatedQuestionsList);
      return;
    }

    if (!this.isRemediate) {
      this.sunburstService.updateSelected(updatedQuestionsList);
    }

    this.saving = false;
    if (!this.showError) {
      this.toastr.success('Saved Successfully');
    }
    this.save.emit(updatedQuestionsList);
  }

  isQuestionRemediated(question: any): boolean {
    return question.isRemediated;
  }

  allQuestionsValid() {
    let commentRequired = false;
    let artifactRequired = false;
    let isAnyUnansweredPartial = false;

    for (const [index, question] of this.questions.entries()) {
      const commentMapper = this.commentAnswerMapper[question.id][this.currentSelectedUserId];

      if (commentMapper && commentMapper?.isCommentRequired) {
        commentRequired = true;
        this.toastr.error(`Comments are mandatory for question ${index + 1}`);
        break;
      }

      if (commentMapper && commentMapper?.isArtifactRequired) {
        artifactRequired = true;
        this.toastr.error(`Artifacts are mandatory for question ${index + 1}`);
        break;
      }

      if (question.answers.find(ans => ans.answer < 0)) {
        isAnyUnansweredPartial = true;
        this.toastr.error(`Please choose a partial value for question ${index + 1}`);
        break;
      }
    }

    if (commentRequired || artifactRequired || isAnyUnansweredPartial) {
      return false;
    }

    return true;
  }

  async saveComment(action: string, questionIdx: number, comment: string = null, type: string = null): Promise<void> {
    if (comment?.trim() === '') {
      this.toastr.error('Please add text in comment');
      return;
    }
    this.isCommentInProgress = true;
    const questionId = this.isRemediate ? this.questions[questionIdx].questionIds[0] : this.questions[questionIdx].id;
    if (action === CommentActionEnum.CREATE && questionId) {
      const date = new Date();
      const commentObj: CreateCommentInput = {
        assessmentId: this.questions[questionIdx].assessmentIds[0],
        questionId,
        userId: this.userId,
        comment,
        date: date.getTime(),
        latestSource: ExportSourceEnum.CYGOV,
        id: uuid(),
        framework: this.collectionService.getSelectedFramework()?.frameworkName,
        type,
      };
      // saving comments draft
      if (this.commentAnswerMapper[this.questions[questionIdx]?.id]) {
        if (!this.commentAnswerMapper[this.questions[questionIdx]?.id][this.currentSelectedUserId]) {
          this.commentAnswerMapper[this.questions[questionIdx]?.id][this.currentSelectedUserId] = {};
        }
        this.commentAnswerMapper[this.questions[questionIdx]?.id][this.currentSelectedUserId].comments =
          commentObj?.comment;
        this.commentAnswerMapper[this.questions[questionIdx]?.id][this.currentSelectedUserId].commentId =
          commentObj?.id;
      } else {
        this.commentAnswerMapper[this.questions[questionIdx]?.id] = {};
        this.commentAnswerMapper[this.questions[questionIdx]?.id][this.currentSelectedUserId].comments =
          commentObj?.comment;
        this.commentAnswerMapper[this.questions[questionIdx]?.id][this.currentSelectedUserId].commentId =
          commentObj?.id;
      }
      this.draftComment(this.commentAnswerMapper[this.questions[questionIdx]?.id], commentObj);
      const createdComment = await this.questionnaireService.createComment(commentObj);
      if (createdComment.id) {
        const logMessage = getLogMessage(LogsKeyEnum.SAVE_COMMENT, this.currentUser.name);
        const log = await this.entityService.addLog(
          this.questions[questionIdx].assessmentIds[0] + '#' + this.questions[questionIdx]?.id,
          this.questions[questionIdx].assessmentIds[0],
          `${LogsTypeEnum.QUESTION}#${this.questions[questionIdx]?.frameworkName.toUpperCase()}`,
          logMessage
        );
        this.questions[questionIdx]?.logs.push(log);
        this.toastr.success('Comment added successfully');
      } else {
        this.toastr.error('Failed Saving Comment');
      }
      this.questionsNewComment[questionId] = '';
      this.questionsAuditComment[questionId] = '';
      this.questions[questionIdx].comments.push(createdComment);
      this.buildQuestionsComments();
    }
    this.isCommentInProgress = false;
    return;
  }
  showMoreLess(qId: any, i: number) {
    if (
      this?.preI !== undefined &&
      this?.prevQid !== undefined &&
      this.userComments[this.prevQid][this.preI]?.show !== undefined &&
      this.userComments[this.prevQid][this.preI].id !== this.userComments[qId][i].id
    ) {
      this.userComments[this.prevQid][this.preI].show = true;
    }
    if (this.userComments[qId][i]?.show !== undefined) {
      {
        this.userComments[qId][i].show = !this.userComments[qId][i].show;
      }
    } else {
      this.userComments[qId][i].show = false;
    }
    this.prevQid = qId;
    this.preI = i;
  }
  async updateComment(commentId, comment, assessmentId, quesiton): Promise<void> {
    if (commentId) {
      const input: UpdateCommentInput = {
        id: commentId,
        latestSource: ExportSourceEnum.CYGOV,
        comment: comment.comment,
        userId: this.userId,
        sType: null,
        assessmentId,
      };
      const commentResult = await this.customApi.UpdateComment(input);
      const logMessage = getLogMessage(LogsKeyEnum.EDIT_COMMENT, this.currentUser.name);
      const log = await this.entityService.addLog(
        quesiton.assessmentIds[0] + '#' + quesiton?.id,
        quesiton.assessmentIds[0],
        `${LogsTypeEnum.QUESTION}#${quesiton?.frameworkName.toUpperCase()}`,
        logMessage
      );
      quesiton?.logs.push(log);
      this.toastr.success('Comment updated successfully');
      delete commentResult.createdAt;
      delete commentResult.exports;
      delete commentResult.latestSource;
      delete commentResult.updatedAt;
      delete commentResult.user;
      delete commentResult.__typename;
      // update global state of comment which has the same commentId
      const updatedComment = await this.questionnaireService.updateComment(commentResult);
      if (updatedComment.id) {
        this.toastr.success('Comment updated successfully');
      } else {
        this.toastr.error('Failed Saving Comment');
      }
    }
  }

  async deleteComment(question, commentId, comment) {
    try {
      if (commentId === '-1') {
        this.toastr.error('Cannot delete comment');
        return;
      }
      if (commentId) {
        // for infosec we need to update the comment sType to null before deleting it so that updateCommentTrigger behave accurately.
        const sType = this.isBnb && question && question.settings && question.settings.isInfoSec ? 'info_delete' : null;
        const input = {
          id: commentId,
          latestSource: ExportSourceEnum.CYGOV,
          comment: comment.comment,
          sType,
          assessmentId: question.assessmentIds[0],
        };
        if (comment.sType !== null || (this.isBnb && question && question.settings && question.settings.isInfoSec)) {
          await this.customApi.UpdateComment(input);
        }
        const deletedComment = await this.customApi.DeleteComment(
          { id: commentId },
          { assessmentId: { eq: question.assessmentIds[0] } }
        );
        // deleting comment from local state
        this.collectionService.deleteComment(deletedComment);
        // removing comment from this component locally
        const index = question?.comments?.findIndex((c: any) => c.id === commentId);
        if (index > -1) {
          question.comments.splice(index, 1);
        }
        const logMessage = getLogMessage(LogsKeyEnum.DELETE_COMMENT, this.currentUser.name);
        const log = await this.entityService.addLog(
          question?.assessmentIds[0] + '#' + question?.id,
          question.assessmentIds[0],
          `${LogsTypeEnum.QUESTION}#${question?.frameworkName.toUpperCase()}`,
          logMessage
        );
        question?.logs.push(log);
        this.toastr.success('Comment deleted successfully');
      }
    } catch (e) {
      console.log('Error while deleting comment', e);
    }
  }

  // flips score between slider value and actual score
  scoreFlipper(value, sliderToVal = true): number {
    return UtilsService.partialAnswersMapping(value, sliderToVal);
  }
  getDate(date: number): string {
    return new Date(date).toLocaleDateString('en-GB');
  }

  getCommentDate(date: number): Date {
    const newDate = new Date(date);
    return newDate;
  }

  getUserName(comment: any): string {
    if (comment.user) {
      return comment.user.items && comment.user.items.length ? comment.user.items[0].name : 'None';
    } else if (comment.userName) {
      return comment.userName;
    } else {
      return this.currentUser ? this.currentUser.name : 'None';
    }
  }

  answerQuestion =
    (updatedQuestionsList: Question[]) =>
    async (question: any, index): Promise<void> => {
      // Return if not answered
      if (!UtilsService.isDefined(this.answers[index].answer) && !UtilsService.isDefined(this.answers[index].file)) {
        return;
      }

      const answerOptionSetting = this.checkOptionIsMandatory(this.answers[index].answer, question);

      // If artifact files not uploaded when mandatory while answering a question YES
      let settingsObject = false;
      for (const prop in question) {
        if (prop === 'settings') {
          settingsObject = true;
          break;
        }
      }

      // Throw error if artifact not uploaded in 1st party & 3rd party vendor compliance framework
      // isFromVendorOrThirdParty gives true if its vendor view or vendor role or third party view
      // if the question is from risk framework and isFromVendorOrThirdParty then its not mandatory to upload artifacts
      if (
        ((settingsObject && question.settings.length && question.settings[0].isArtifactsMandatory) ||
          (this.isFromVendorOrThirdParty && !this.riskFrameworks.includes(question.frameworkName))) &&
        answerOptionSetting?.artifact &&
        !(this.answers[index] && UtilsService.isDefined(this.answers[index].file) && this.answers[index].file.length)
      ) {
        this.toastr.error('Artifacts are mandatory');
        this.answers[index].answer = this.prevAns ? this.prevAns : this.answers[index].answer;
        this.showError = true;
        return;
      }

      if (
        ((settingsObject && question.settings.length && question.settings[0].isCommentsMandatory) ||
          (this.isFromVendorOrThirdParty && !this.riskFrameworks.includes(question.frameworkName))) &&
        (UtilsService.formatAnswerValue(this.currentSelectedAnswer) === AnswerEnum.NOT_APPLICABLE ||
          UtilsService.formatAnswerValue(this.commentAnswerMapper[question.id]?.[this.currentSelectedUserId]?.ans) ===
            AnswerEnum.NOT_APPLICABLE) &&
        !(UtilsService.isDefined(question?.comments) && question?.comments?.length)
      ) {
        this.toastr.error('Comments Required');
        this.showError = true;
        return;
      }
      // Initialize answer array if not exists
      if (!question.answers) {
        question.answers = [];
      }

      // Add answer in question answers array
      let userAnswer = this.answers[index];

      if (
        userAnswer?.id &&
        !this.cachedAnswers.some(
          oldAns => oldAns?.id === userAnswer?.id && oldAns?.file?.length !== userAnswer?.file?.length
        )
      ) {
        return;
      }

      // populate actual score if partial is selected
      if (this.isPartialSelected[index]) {
        // IF scoreFlipper return undefined, it means we click partial but 50% didn't selected. so set 5 value
        const partialScore = this.scoreFlipper(this.sliderScore[index])
          ? this.scoreFlipper(this.sliderScore[index])
          : 5;
        this.answers[index].answer = partialScore;
      }
      // Remove typename for updating s3 files fetched
      if (userAnswer.file) {
        userAnswer.file.forEach(input => delete input.__typename);
      }

      // Update answer if already answered or add new answer if unanswered
      userAnswer = {
        ...userAnswer,
        comment: `${this.comment}${userAnswer.comment ? userAnswer.comment : ''}\n`,
      };
      question.riskAction = this.comment ? RiskAction.REMEDIATED : null;
      userAnswer.isActionTaken = this.comment ? true : false;
      question.questionId = this.isRemediate
        ? question?.answers.length
          ? question?.questionIds[0]
          : question.id
        : question.id;
      const id = this.isRemediate ? question?.questionIds[0] : question.id;
      const targetId = `${question.assessmentIds[0]}#${id}`;
      const type = `${LogsTypeEnum.QUESTION}#${question.frameworkName.toUpperCase()}`;

      this.currentSelectedUserId = this.isRemediate ? userAnswer?.user?.id : this.currentSelectedUserId;
      const savedQuestion = await this.questionnaireService.addAnswerWithAssignments(
        question,
        this.currentSelectedUserId,
        userAnswer,
        this.assignments,
        question.questionId,
        null,
        targetId,
        type,
        this.currentUser.name
      );
      // remove duplicate assignments from questions object (if any)
      question.assignments = this.getUniqueAssignments(question.assignments, true);
      this.collectionService.addNewAnswerInCache(
        question.questionId,
        savedQuestion.answers.filter(ans => ans?.id),
        true
      );
      const logMessage = getLogMessage(LogsKeyEnum.REMEDIATE_TASK, this.currentUser.name);
      if (this.isRemediate) {
        this.entityService.addLog(
          question?.assessmentIds[0] + '#' + question?.questionIds[0],
          question.assessmentIds[0],
          `${LogsTypeEnum.QUESTION}#${question?.frameworkName.toUpperCase()}`,
          logMessage
        );
      }
      updatedQuestionsList.push(question);
    };

  async onFilePicked(event: any, questionIdx: number): Promise<void> {
    let isAnyFileToUpload = false;
    if (event.target.files.length) {
      try {
        // compress file to zip and upload to s3 bucket
        // storing zipfiles multiple instances
        const zipFiles = (
          await Promise.all(
            Array.from(event.target.files).map(async (file: File) => {
              if (file.type === 'application/pdf') {
                if (await this.fileService.scanPdfFile(file)) {
                  this.toastr.warning('Your File Contain Malicious Content');
                  return null;
                }
              }
              if (FileService.validateFileSize(file)) {
                this.toastr.warning(file.name + ' Size can not be greater than 30MB');
                return null;
              } else if (!FileService.validateFileType(file, FileTypeEnum.ARTIFACTS)) {
                this.toastr.warning(file.name + ' File type not supported');
                return null;
              } else {
                isAnyFileToUpload = true;
                const fileInstance = new JSZip().file(file.name, file);
                return {
                  name: file.name,
                  file: (await fileInstance.generateAsync({ type: 'blob' })) as any,
                };
              }
            })
          )
        ).filter(file => UtilsService.isDefined(file));
        if (isAnyFileToUpload) {
          // this.toastr.info('Uploading...');

          // upload zip files to s3 bucket
          const s3Inputs = zipFiles.map(this.mapS3FileInput);
          if (!UtilsService.isDefined(this.answers[questionIdx].file)) {
            this.answers[questionIdx].file = [];
          }
          this.questions[questionIdx].file = await Promise.all(
            s3Inputs.map(async input => {
              const savedFile = await this.fileService.uploadToS3(input);
              const currentUserAnswerIndex = this.questions[questionIdx]?.answers.findIndex(
                ans => ans?.user?.id === this.currentSelectedUserId
              );

              if (currentUserAnswerIndex > -1) {
                if (!this.questions[questionIdx].answers[currentUserAnswerIndex].file) {
                  this.questions[questionIdx].answers[currentUserAnswerIndex].file = [];
                }

                this.questions[questionIdx].answers[currentUserAnswerIndex].updatedAt = new Date().toISOString();
                this.buildAnswerList();
                this.cachedAnswers = JSON.parse(JSON.stringify(this.answers));

                this.questions[questionIdx].answers[currentUserAnswerIndex].file.push(savedFile);
              } else {
                this.questions[questionIdx].answers.push({
                  answer: null,
                  answerName: null,
                  user: (this.currentUser = await this.userService.getCurrentUser()),
                  file: [savedFile],
                  updatedAt: new Date().toISOString(),
                });
              }
              this.tempQuestionsAnswers = JSON.parse(JSON.stringify(this.questions));
              this.isPartialSelected[questionIdx] =
                this.questions[questionIdx]?.answers[currentUserAnswerIndex]?.answerName?.toLowerCase() ===
                AnswerEnum.PARTIAL.toLowerCase();
              this.buildAnswerList();
            })
          );
          const logMessage = getLogMessage(
            LogsKeyEnum.ARTIFACT_UPLOAD,
            this.currentUser.name,
            null,
            null,
            this.questions[questionIdx].file?.length
          );
          const log = await this.entityService.addLog(
            this.questions[questionIdx].assessmentIds[0] + '#' + this.questions[questionIdx]?.questionIds[0],
            this.questions[questionIdx].assessmentIds[0],
            `${LogsTypeEnum.QUESTION}#${this.questions[questionIdx]?.frameworkName.toUpperCase()}`,
            logMessage
          );
          this.questions[questionIdx]?.logs.unshift(log);
        }
      } catch (e) {
        this.logger.error('uploadFiles - Error: ', e);
        this.toastr.error('Failed to upload file');
      }
    }
  }

  mapS3FileInput = (file): S3FileInput => {
    return {
      name: file.name,
      entityId: this.entityId,
      fileType: FileTypeEnum.ARTIFACTS,
      contentType: file.file.type,
      body: file.file,
      date: Date.now(),
    };
  };

  async downloadFile(s3Inputs: S3FileInput[], questionIdx: any): Promise<void> {
    try {
      s3Inputs = this.tempQuestionsAnswers[questionIdx].answers
        .map(ans => ans.file)
        .filter(file => file)
        .flat();
      this.toastr.info('Preparing file...');
      const blobs = await Promise.all(s3Inputs.map(this.createBlob));
      // Creating one zip to be downloaded
      const zip = new JSZip();
      blobs.forEach(file => {
        zip.file(file.name.concat('.zip'), file.blob);
      });
      const zipFile = await zip.generateAsync({ type: 'blob' });

      saveAs(zipFile, `Artifacts-${this.entityId}.zip`);
    } catch (e) {
      this.logger.error(e);
      this.toastr.error('Failed downloading file');
    }
  }

  createBlob = async (s3Input: S3FileInput): Promise<{ blob: any; name: string }> => {
    const url = await this.fileService.downloadFromS3(s3Input);
    const response = await fetch(url);
    const blob = await response.blob();
    return {
      blob,
      name: s3Input.name,
    };
  };

  answerNumToEnum(value: number): AnswerEnum {
    return UtilsService.answerNumToEnum(value);
  }

  // check if the answer is partial
  answerEnumToNum(value: string, frameworkAnswersWithSettings: any, answerValue?: number): number {
    if (this.isBnb) {
      return this.bnbAnswerEnumToNum(value, frameworkAnswersWithSettings);
    }
    if (answerValue >= 2.5 && answerValue <= 9) {
      return UtilsService.getScoreOrKey(value, frameworkAnswersWithSettings, answerValue);
    }
    return UtilsService.getScoreOrKey(value, frameworkAnswersWithSettings);
  }

  bnbAnswerEnumToNum(value: string, frameworkAnswersWithSettings): number {
    return UtilsService.getScoreOrKey(value, frameworkAnswersWithSettings);
  }

  checkIfFile(selectedAnswer = 'yes', question = null, questionIndex): boolean {
    const answerOptionSetting = this.checkOptionIsMandatory(selectedAnswer, question);

    if (selectedAnswer?.toLowerCase() === ManagerActionEnum.APPROVE.toLowerCase() || answerOptionSetting?.artifact) {
      if (
        !this.tempQuestionsAnswers[questionIndex]?.answers?.find(qAnswer => {
          return (
            UtilsService.isDefined(qAnswer?.file) &&
            qAnswer?.file?.length > 0 &&
            qAnswer.user.id === this.currentSelectedUserId
          );
        }) &&
        !this.tempQuestionsAnswers[questionIndex]?.managerAnswers?.find(qAnswer => {
          return (
            UtilsService.isDefined(qAnswer?.file) &&
            qAnswer?.file?.length > 0 &&
            qAnswer.user.id === this.currentSelectedUserId
          );
        })
      ) {
        // not removing this code as it might be time saving,
        // if we need to reveert the old functionality regarding mandatory artifacts
        // this.toastr.info('Please upload artifacts before answering YES', 'Mandatory Artifacts');
        return false;
      }
    }
    return true;
  }

  checkOptionIsMandatory(possibleAnswer: string, question: any) {
    const possibleAnswers = question.possibleAnswers?.map(item => item?.toLowerCase());
    const matchedIndex = possibleAnswers.indexOf(String(possibleAnswer)?.toLowerCase());
    const questionSettings = question.settings[0];
    return {
      artifact:
        !UtilsService.isDefined(questionSettings.suggestedArtifactAnswers) ||
        questionSettings.suggestedArtifactAnswers === 'undefined'
          ? questionSettings.isArtifactsMandatory
          : questionSettings.isArtifactsMandatory &&
            questionSettings.suggestedArtifactAnswers.includes(matchedIndex + 1),
      comment:
        !UtilsService.isDefined(questionSettings.requiredCommentAnswers) ||
        questionSettings.requiredCommentAnswers === 'undefined'
          ? questionSettings.isCommentsMandatory
          : questionSettings.isCommentsMandatory && questionSettings.requiredCommentAnswers.includes(matchedIndex + 1),
    };
  }

  /**
   *
   * @param possibleAnswer (YES,NO,NOT APPLICABLE,PARTIAL)
   * @returns boolean
   */
  mandatoryTextFunction(possibleAnswer: string, question, questionIndex, checkingComment = false): boolean {
    if (question.isAverageSelected) {
      return;
    }

    if (!possibleAnswer) {
      possibleAnswer = this.currentPossibleAnswer;
    }

    let isCommentRequired = false;
    let isArtifactRequired = false;

    const answerOptionSetting = this.checkOptionIsMandatory(possibleAnswer, question);

    if (possibleAnswer === this.commentAnswerMapper[question.id]?.[this.currentSelectedUserId]?.ans) {
      if (UtilsService.formatAnswerValue(possibleAnswer) === this.answerEnum.PARTIAL) {
        this.isPartialSelected[questionIndex] = true;
      } else {
        this.isPartialSelected[questionIndex] = false;
      }
      if (!this.commentAnswerMapper[question.id]?.[this.currentSelectedUserId]?.comments) {
        if (
          UtilsService.formatAnswerValue(possibleAnswer) === this.answerEnum.NOT_APPLICABLE &&
          UtilsService.formatAnswerValue(this.commentAnswerMapper[question.id]?.[this.currentSelectedUserId]?.ans) ===
            this.answerEnum.NOT_APPLICABLE
        ) {
          this.mandatoryText = '(Mandatory comment Please Specify)';
          isCommentRequired = true;
          // eslint-disable-next-line dot-notation
          this.commentAnswerMapper[question.id][this.currentSelectedUserId].isCommentRequired = true;
        }
        if (answerOptionSetting?.comment && question?.settings[0]?.isCommentsMandatory) {
          this.mandatoryText = '(Mandatory comment Please Specify)';
          isCommentRequired = true;
          // eslint-disable-next-line dot-notation
          this.commentAnswerMapper[question.id][this.currentSelectedUserId]['isCommentRequired'] = true;
        }
      }
      const file = this.checkIfFile(possibleAnswer, question, questionIndex);
      if (
        !file &&
        this.commentAnswerMapper[question.id]?.[this.currentSelectedUserId]?.ans === possibleAnswer &&
        question?.settings[0]?.isArtifactsMandatory &&
        answerOptionSetting?.artifact
      ) {
        this.mandatoryText = '(Mandatory artifact Please Specify)';
        isArtifactRequired = true;
        // eslint-disable-next-line dot-notation
        this.commentAnswerMapper[question.id][this.currentSelectedUserId]['isArtifactRequired'] = true;
      }

      if (isArtifactRequired && isCommentRequired) {
        this.mandatoryText = '(Mandatory artifact and comment Please Specify)';
        this.isPartialSelected[questionIndex] = false;

        return true;
      } else if ((isArtifactRequired || isCommentRequired) && !checkingComment) {
        this.isPartialSelected[questionIndex] = false;
        return true;
      } else if (isCommentRequired && checkingComment) {
        this.isPartialSelected[questionIndex] = false;
        return true;
      }

      // eslint-disable-next-line dot-notation
      this.commentAnswerMapper[question.id][this.currentSelectedUserId]['isCommentRequired'] = false;
      // // eslint-disable-next-line dot-notation
      this.commentAnswerMapper[question.id][this.currentSelectedUserId].isArtifactRequired = false;
      this.mandatoryText = '';

      return false;
    }
  }

  /**
   * this function will keep record of comments and answers if user select YES or NOT Applicable
   * @param answer
   * @param questionIndex
   */

  createCache(answer: any, questionIndex): void {
    // this.isCommentsMandatory = this.question?.settings?.isCommentsMandatory;
    // this.myAnswer = selectedAnswer;
    const answerSettings = this.checkOptionIsMandatory(answer, this.questions[questionIndex]);
    this.commentAnswerMapper[this.questions[questionIndex]?.id][this.currentSelectedUserId].ans = answer;
    // this.commentAnswerMapper = this.collectionService.commentAnswerMapper;

    if (answerSettings && (answerSettings.comment || answerSettings.artifact)) {
      // this.commentAnswerMapper[this.questions[questionIndex]?.id] = {};
      this.commentAnswerMapper[this.questions[questionIndex]?.id][this.currentSelectedUserId].ans = answer;
    } else if (UtilsService.formatAnswerValue(answer) === this.answerEnum.NOT_APPLICABLE) {
      if (this.commentAnswerMapper[this.questions[questionIndex]?.id]?.[this.currentSelectedUserId]?.ans) {
        this.commentAnswerMapper[this.questions[questionIndex]?.id][this.currentSelectedUserId].ans = answer;
      } else {
        if (!this.commentAnswerMapper[this.questions[questionIndex]?.id]) {
          this.commentAnswerMapper[this.questions[questionIndex]?.id] = {};
        }
        this.commentAnswerMapper[this.questions[questionIndex]?.id][this.currentSelectedUserId].ans = answer;
      }

      this.collectionService.saveDraft(this.commentAnswerMapper);
    } else {
      if (!this.commentAnswerMapper[this.questions[questionIndex]?.id]) {
        this.commentAnswerMapper[this.questions[questionIndex]?.id] = {};
      }
      // this.commentAnswerMapper[this.questions[questionIndex]?.id][this.currentSelectedUserId].ans = '';
      // this.commentAnswerMapper[this.questions[questionIndex]?.id].isArtifactUploaded = false;
    }
  }

  selectionChanged(sliderScore, answer, questionIdx) {
    if (this.questions[questionIdx]?.settings[0]?.isCollaborative) {
      this.currentSelectedUserId = this.userId;
    }

    this.currentPossibleAnswer = answer;

    if (!this.commentAnswerMapper[this.questions[questionIdx].id][this.currentSelectedUserId]) {
      this.commentAnswerMapper[this.questions[questionIdx].id][this.currentSelectedUserId] = {};
    }

    const isFileExist = this.questions[questionIdx].answers.find(
      ans => this.currentSelectedUserId === ans?.user?.id && ans?.file?.length
    );

    if (isFileExist) {
      this.commentAnswerMapper[this.questions[questionIdx].id][this.currentSelectedUserId].isArtifactRequired = false;
    }

    this.createCache(answer, questionIdx);

    this.isPartialSelected[questionIdx] = answer.toLowerCase() === AnswerEnum.PARTIAL.toLowerCase();

    this.prevAns = this.answers[questionIdx].answer;
    // this.answers[questionIdx].answer = this.currentSelectedAnswer = answer;
    const remediatedObjIndex = this.remediatedItemsList.findIndex(obj => {
      return obj.answerId === this.answers[questionIdx].id;
    });

    const userId = this.questions[questionIdx]?.selectedUserAssignment?.userId
      ? this.questions[questionIdx]?.selectedUserAssignment?.userId
      : this.questions[questionIdx]?.selectedUserAssignment?.id;
    const index = this.questions[questionIdx].answers?.findIndex(ans => ans?.user?.id === this.currentSelectedUserId);
    if (index > -1) {
      this.questions[questionIdx].answers[index] = {
        answer: UtilsService.answerEnumToNum(answer.toUpperCase(), sliderScore ? sliderScore : -1),
        answerName: answer,
        user: this.questions[questionIdx].answers[index].user,
        file: isFileExist?.file ? isFileExist?.file : [],
        updatedAt: new Date().toISOString(),
      };
    } else {
      this.questions[questionIdx].answers.push({
        answer: UtilsService.answerEnumToNum(answer.toUpperCase(), sliderScore ? sliderScore : -1),
        answerName: answer,
        user: this.currentUser,
        file: isFileExist?.file ? isFileExist?.file : [],
        answerId: this.answers[questionIdx].id,
        updatedAt: new Date().toISOString(),
      });
    }

    if (remediatedObjIndex === -1) {
      this.remediatedItemsList.push({
        answerId: this.answers[questionIdx].id,
        sliderScore,
        answer,
        questionIdx,
      });
    } else {
      this.remediatedItemsList[remediatedObjIndex].sliderScore = sliderScore;
      this.remediatedItemsList[remediatedObjIndex].answer = answer;
    }
    this.buildAnswerList();
  }

  isNotAlreadyAssigned(questionId: string, userId: string) {
    if (this.assignedUsers[questionId]?.length) {
      return this.assignedUsers[questionId]?.findIndex(user => user?.id === userId) === -1;
    } else {
      return true;
    }
  }

  onUsernameClicked(question, index, selectedUserAssignment: GetAssignmentQuery): void {
    this.isUserItSelf = this.currentUser?.id === selectedUserAssignment?.id;

    this.currentSelectedUserId = selectedUserAssignment?.userId
      ? selectedUserAssignment?.userId
      : selectedUserAssignment?.id;

    if (!this.commentAnswerMapper[question.id][this.currentSelectedUserId]) {
      this.commentAnswerMapper[question.id][this.currentSelectedUserId] = {};
    }

    if (this.hasPermission && !question.settings[0].isCollaborative) {
      const answer = question.answers?.find(ans => ans?.user.id === this.currentSelectedUserId);
      if (answer && Object.keys(question.frameworkAnswersWithSettings).includes('PARTIAL')) {
        this.isPartialSelected[index] = !(answer?.answer === 1 || answer?.answer === 0 || answer?.answer > 9);
      }
      question.isAverageSelected = false;
      question.selectedUserAssignment = selectedUserAssignment;
    }
    this.buildAnswerList();
  }

  showAverageAnswer(question, index): void {
    question.isAverageSelected = true;

    // not adding a check that just checks the length of the answers array
    // because when we upload file we create the answers array but the value in the answer remains null
    // so need to check if there exist any valid answer value in array
    const isAnswerAvailable = question?.answers?.some(ans => {
      if (ans.answer || ans.answer === 0) {
        return true;
      }
    });
    if (isAnswerAvailable) {
      const averageScoreObj = UtilsService.calculateAverageObject(question, question.frameworkName);
      const averageOfScore = averageScoreObj.answer;
      const averageName = averageScoreObj.answerName;

      this.answers[index].answer = averageOfScore;
      this.answers[index].answerName = averageName;
      this.isPartialSelected[index] = averageName.toLowerCase() === 'partial';
      if (this.isPartialSelected[index]) {
        this.sliderScore[index] = this.scoreFlipper(this.answers[index].answer, false);
      }
    }
  }

  seeAllActivity(question): void {
    question.isSeeAllActivity = !question?.isSeeAllActivity;
    if (question?.isVersionClicked) {
      question.isVersionClicked = false;
    }
    if (!question.showAverageAvatar) {
      question.showAverageAvatar = !question.showAverageAvatar;
    }
  }

  setVersionAnswer(index: number, answer) {
    this.answers[index] = answer;
    this.isPartialSelected[index] =
      this.answers[index] &&
      this.answers[index].answer &&
      this.answers[index].answer >= 2.5 &&
      this.answers[index].answer < 10;
    if (this.isPartialSelected[index]) {
      this.sliderScore[index] = this.scoreFlipper(this.answers[index].answer, false);
    }
  }

  openVersions(question, qIdx: number): void {
    question.showAverageAvatar = !question.showAverageAvatar;
    question.isVersionClicked = !question.isVersionClicked;
    if (question?.questionVersions?.length) {
      if (question.isVersionClicked) {
        question.questionVersions[0].selected = true;
        this.setVersionAnswer(qIdx, question.questionVersions[0]?.data);
      } else {
        for (const ver of question.questionVersions) {
          ver.selected = false;
        }
        if (question.isAverageSelected) {
          this.showAverageAnswer(question, qIdx);
        } else {
          this.buildAnswerList();
        }
      }
    }
  }

  selectVersion(question, qIdx: number, version): void {
    for (const ver of question.questionVersions) {
      if (ver.date === version.date) {
        ver.selected = true;
      } else if (ver.selected) {
        ver.selected = false;
      }
    }
    this.setVersionAnswer(qIdx, version.data);
  }

  /**
   * @param element is type HTMLElement on which the Scroll is added
   * @param moveDirection is type of ArrowEnum either 'up' or 'down' string
   */
  moveScrollLog(question, moveDirection: string): void {
    if (moveDirection.toLowerCase() === ArrowsEnum.UP.toLowerCase() && !question.disableUpArrow) {
      question.logsStartIndex -= 1;
      question.logsLastIndex -= 1;
    } else if (moveDirection.toLowerCase() === ArrowsEnum.DOWN.toLowerCase() && !question.disableDownArrow) {
      question.logsStartIndex += 1;
      question.logsLastIndex += 1;
    }

    if (question.questionLogs) {
      question.questionLogs = question?.logs?.slice(question.logsStartIndex, question.logsLastIndex);
      question.disableUpArrow = question.logsStartIndex <= 0;
      question.disableDownArrow = question.logsLastIndex >= question?.logs?.length;
    }
  }

  editComments(question, isOpen) {
    question.isEditComments = !question.isEditComments;
    question.showFullComment = false;
    question.isCommentDeleted = false;
    if (!isOpen) {
      this.buildQuestionsComments();
      question.commentsToUpdate = [];
      question.commentsToDelete = [];
    }
  }

  saveResult(question) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    question.commentsToUpdate.map(async comment => {
      await this.updateComment(comment.id, comment, question.assessmentId, question);
      this.userComments[question.id].forEach(cmnt => {
        if (cmnt.id === comment.id) {
          cmnt.comment = comment.comment;
        }
      });
    });
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    question.commentsToDelete.map(async comment => {
      await this.deleteComment(question, comment.id, comment);
    });
    question.isEditComments = false;
    question.commentsToUpdate = [];
    question.commentsToDelete = [];
  }

  showMore(question, comment) {
    question.showFullComment = true;
    question.currentComment = comment;
  }

  showLess(question) {
    question.showFullComment = false;
  }

  addCommentIds(question, commentId, event?) {
    if (commentId !== -1) {
      question.commentsToUpdate.push({ id: commentId, comment: event.target.value });
    }
  }

  deleteComments(question, index: number) {
    question.isCommentDeleted = true;
    const comment: any = this.userComments[question.id][index];
    this.userComments[question.id].splice(index, 1);
    question.commentsToDelete.push(comment);
  }

  // Checks for either the comment input is focused or not
  focusing(isFocused, event) {
    if (isFocused) {
      event.target.classList.add('input-focused');
    } else {
      event.target.classList.remove('input-focused');
    }
  }

  toggle(questionId): void {
    const totalQuestions = this.questions.length;
    this.collapsedQuestion = Array.from(Array<boolean>(totalQuestions).fill(false));
    this.collapsedQuestion[questionId] = !this.collapsedQuestion[questionId];
  }

  toggleCollapse(questionIdx) {
    this.collapsedQuestion[questionIdx] = !this.collapsedQuestion[questionIdx];
  }

  sortCommentsAscending(): void {
    this.questions.forEach(task => {
      task.comments.sort((a: GetCommentQuery, b: GetCommentQuery) => a.date - b.date);
    });
  }

  ifHigherRoles() {
    const role = this.userService.getCurrentRole().name.toUpperCase();
    return (
      role === RoleEnum.ADMIN ||
      role === RoleEnum.LEADER ||
      role === RoleEnum.MSSP ||
      role === AppLevelRoleEnum.ENTITY_LEADER.toUpperCase()
    );
  }

  getFrameworkName(val: string): string {
    const isFrameworkKey = this.domainFrameworkService.isFrameworkKey(val);
    return isFrameworkKey ? this.domainFrameworkService.getFrameworkName(val) : val;
  }

  ngOnDestroy(): void {
    this.subscription.forEach(listener => listener.unsubscribe());
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  lastAnswer(question, assignment, index) {
    if (question.isVersionClicked) {
      const ans = this.answers[index];
      return question?.settings[0]?.isCollaborative && ans.user.id === assignment.user.id;
    } else {
      const lastAnswer = UtilsService.sortByProp(question.answers, 'updatedAt', SortDirectionEnum.DESCENDING)[0] as any;
      return question?.settings[0]?.isCollaborative && lastAnswer?.user?.id === assignment?.user?.id;
    }
  }

  draftComment(commentAnswerMapper, commentObj) {
    if (
      !commentAnswerMapper[this.currentSelectedUserId] ||
      !Object.keys(commentAnswerMapper[this.currentSelectedUserId])?.length
    ) {
      commentAnswerMapper[this.currentSelectedUserId] = {};
    }
    commentAnswerMapper[this.currentSelectedUserId].comments = commentObj?.comment;
    commentAnswerMapper[this.currentSelectedUserId].commentId = commentObj?.id;
  }

  isOptionChecked(questionIdx, answer, question): boolean {
    if (this.answers[questionIdx].answer === null) {
      return false;
    }

    const res =
      this.answers[questionIdx].answer >= 0 &&
      this.answers[questionIdx].answer ===
        this.answerEnumToNum(answer, question.frameworkAnswersWithSettings, this.answers[questionIdx].answer);

    return res;
  }

  showUploadFileIcon(question): boolean {
    if (question.isAverageSelected) {
      return true;
    } else if (question?.settings[0].isCollaborative) {
      return false;
    } else if (!this.isUserItSelf) {
      return true;
    } else {
      return false;
    }
  }
}
