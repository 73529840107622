/* eslint-disable @typescript-eslint/no-misused-promises */
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { APIService, CreateUserInput, GetUserQuery, RoleEnum } from 'app/API.service';
import { UserService } from '../user.service';
import { UtilsService } from '../utils.service';
import { ActivatedRoute } from '@angular/router';
import { AppLevelRoleEnum } from '../enums/appLevelRoles.enum';
import { ToastrService } from 'ngx-toastr';
import { CUSTOMAPIService } from 'app/custom-api.service';
import { AddParticipantModalComponent } from '../add-participant-modal/add-participant-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/auth/auth.service';
import { CollectionService } from 'app/collection/collection.service';
import { from } from 'rxjs';

interface GetUserExtended extends GetUserQuery {
  checked?: boolean;
}

/**
 * Broker Interface
 */
interface _Broker {
  name: string;
  email: string;
}
@Component({
  selector: 'cygov-user-listing',
  templateUrl: './user-listing.component.html',
  styleUrls: ['./user-listing.component.scss'],
})
export class UserListingComponent implements OnInit, OnChanges {
  @Input() isBpcList: boolean = false;
  @Input() showUserName: boolean = true;
  @Input() users: GetUserExtended[];
  @Input() listLabel: string = 'Items';
  @Input() isRequiredLabel: boolean = false;
  @Input() addVendor: boolean = false;
  @Input() isBulkVendor: boolean = false;
  @Input() currentUser: GetUserExtended;
  @Input() currentSelectedUsers: any[];
  @Output() selectedUser = new EventEmitter<any>();
  @Output() selectedBpcUser = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<any>();
  @Output() newUsers: EventEmitter<any> = new EventEmitter<any>();
  @Input() auditMode: boolean = false;
  @Input() multiple: boolean = false;
  @Input() manager;
  @Input() currentVendorId: string = '';
  @Input() isSearchShow: boolean = true;
  @Input() SearchIconSvgName: string = 'search-icon';
  @Input() isAddRiskOwner: boolean = false;
  @Input() showAddButton = false; // Either show Add button or not
  @Input() isFromThirdParty: boolean = false;
  @Input() userListSeparator: boolean = false;
  @Input() businessPointOfContact: any;
  @Input() showUserRole: boolean = true;
  @Input() showInitials: boolean = true;
  @Input() showToolTip: boolean = false;
  @Input() midMarketSelectedBrokers: _Broker[] = []; //* Midmarket selected Brokers list
  @Input() removeManager: any;
  @Input() isFromBulkVendor: boolean = false; // to customize the add user modal from bulk vendors
  @Input() isShowColon: boolean = false; // to show the colon (:) at the end of heading
  @Input() isShowRoleName: boolean = false;
  isInformatica = UtilsService.isInformatica || UtilsService.isInformaticaUat;

  isAddUserClicked: boolean = false;
  showManagerSelection: boolean = true;
  email: string;
  name: string;
  entityId: string;
  addedUsers: CreateUserInput[] = [];
  existedUsers: CreateUserInput[] = [];
  newUsersList: any[] = [];
  isENBD: boolean = UtilsService.isENBD;
  isUserNameEllipsis: boolean = false;

  searchText = '';
  filteredUserList: GetUserExtended[];
  cachedUserList: GetUserExtended[];
  isMidMarket: boolean = UtilsService.isMidMarket;
  subEntityId: any;
  rolesList: any;
  AddUserModalOptions: NgbModalOptions = {
    backdrop: 'static',
    windowClass: 'add-collection-user-modal',
    backdropClass: 'add-collection-user-modal-backdrop',
    centered: true,
    size: 'lg',
  };
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private api: APIService,
    private customApi: CUSTOMAPIService,
    private authService: AuthService,
    private collectionService: CollectionService,
    private modalService: NgbModal
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.manager) {
      this.toggleSelectedManager(this.manager);
    }
    if (this.removeManager) {
      this.filteredUserList.forEach(userItem => {
        if (userItem.id === this.removeManager.id && this.removeManager.checked) {
          userItem.checked = false;
        }
      });
    }

    // * if selected brokers list changes
    if (changes.midMarketSelectedBrokers) {
      this.checkSelectedBrokers();
    }

    if (!changes?.users?.firstChange) {
      this.sortUsersList();
    }
  }

  ngOnInit(): void {
    this.cachedUserList = this.users;
    this.filteredUserList = this.cachedUserList;
    this.entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');

    from(this.collectionService.getVendorRoles()).subscribe(allRoles => {
      this.rolesList = allRoles;
    });
    // if (this.users && this.multiple) {
    //   this.selectManagers(this.users);
    // }
    if (this.addVendor) {
      this.filteredUserList.forEach(userItem => {
        userItem.checked = userItem.id === this.currentVendorId;
      });
    }
    if (this.currentUser) {
      // this.changeSelectedManager(this.currentUser);
      const index = this.filteredUserList.findIndex(item => item.id === this.currentUser.id);
      if (index !== -1) {
        this.currentUser.checked = true;
        this.filteredUserList[index] = this.filteredUserList[0];
        this.filteredUserList[0] = this.currentUser;
      }
    }
    if (this.isAddRiskOwner && this.currentSelectedUsers && this.currentSelectedUsers.length) {
      this.currentSelectedUsers.forEach(owners => {
        const checkedUser = this.filteredUserList.find(filteredUser => filteredUser.id === owners.id);
        if (checkedUser) {
          checkedUser.checked = true;
        }
      });
    }
    if (this.isENBD) {
      this.filteredUserList?.forEach(user => {
        if (user.id === this.businessPointOfContact?.id) {
          user.checked = true;
        }
      });
    }

    this.checkSelectedBrokers();
    this.sortUsersList();
  }

  sortUsersList() {
    this.filteredUserList =
      this.filteredUserList?.sort((a, b) => {
        const first = a?.name.toLowerCase().trim();
        const second = b?.name.toLowerCase().trim();
        return first.localeCompare(second); // Directly compare strings
      }) || [];
  }

  /**
   * *** MIDMARKET FUNCTION****
   * Function Checks Brokers from the users list
   */
  checkSelectedBrokers() {
    this.cachedUserList = this.users;
    this.filteredUserList = this.cachedUserList;
    // * To check the selected Brokers from midmarket that were already in the list
    if (this.midMarketSelectedBrokers?.length > 0 && this.filteredUserList) {
      this.midMarketSelectedBrokers.forEach(selectedBroker => {
        // Check if the email property of selectedBroker is not null
        if (selectedBroker.email) {
          const checkedUser = this.filteredUserList.find(filteredUser => filteredUser.email === selectedBroker.email);
          if (checkedUser) {
            checkedUser.checked = true;
          }
        }
      });
    }
  }

  applyManagerSearch(): void {
    if (this.searchText === '') {
      this.filteredUserList = this.cachedUserList;
    } else {
      this.filteredUserList = this.cachedUserList.filter(user => {
        return user.name.toLowerCase().includes(this.searchText.toLowerCase());
      });
    }
    this.sortUsersList();
  }

  changeSelectedManager(user: any): void {
    this.users.forEach(u => {
      u.checked = u.id === user.id ? !user.checked : false;
    });
    if (this.isFromThirdParty || this.isBulkVendor) {
      user.checked = true;
    } else {
      user.checked = !user.checked;
    }
    if (!this.showAddButton) {
      this.selectedUser.emit(user);
    }
    this.filteredUserList.forEach(userItem => {
      if (userItem.id === user.id && user.checked) {
        userItem.checked = true;
      } else {
        if (!this.multiple) {
          userItem.checked = false;
        }
      }
    });
  }

  toggleSelectedManager(user: any) {
    // this.selectedUser.emit(user);
    this.filteredUserList?.forEach(userItem => {
      if (userItem?.id === user?.id) {
        userItem.checked = false;
      }
    });
  }

  removeUserFromList(index: number): void {
    this.addedUsers.splice(index, 1);
  }

  addNewUser(): void {
    this.isAddUserClicked = true;
    this.newUsersList.push({ name: '', email: '' });
  }
  disableField(n) {
    this.newUsersList = this.newUsersList.filter(user => user !== n);
  }
  resetFields() {
    this.isAddUserClicked = false;
    this.newUsersList = this.newUsersList.filter(user => user.email === '');
  }
  addUsers(e): void {
    // this.newUsersList = this.newUsersList.filter(user => user.email !== '');
    console.log(e);
    // console.clear();
    this.newUsersList.forEach(async user => {
      await this.checkIfUserExist(user);
    });
  }
  async checkIfUserExist(user) {
    if (user && user.email) {
      let userExist: any = await this.userService.getUserByEmail(user.email);
      userExist = userExist?.items[0];
      if (!userExist) {
        this.addedUsers.push(user);
        this.resetFields();
        this.newUsers.emit(this.addedUsers);
      } else {
        const isNotHigherRole: any = await this.isNotFromHigherRoles(userExist);
        if (isNotHigherRole) {
          this.addedUsers.push(userExist);
          this.newUsers.emit(this.addedUsers);
          this.resetFields();
        } else {
          this.toastr.error('Can not Update Higher Role User');
        }
      }
    }
  }
  async updateUsers(user, index) {
    this.addedUsers.splice(index, 1);
    await this.checkIfUserExist(user);
  }
  async isNotFromHigherRoles(user): Promise<boolean> {
    let role: any = await this.customApi.GetRole(user.roleId);
    role = role.name.toUpperCase();
    return (
      role !== RoleEnum.ADMIN &&
      role !== RoleEnum.LEADER &&
      role !== RoleEnum.MSSP &&
      role !== AppLevelRoleEnum.ENTITY_LEADER.toUpperCase()
    );
  }

  addSelectedUser(): void {
    // here check the checked User
    const index = this.users.findIndex(item => item.checked);
    if (index > -1) {
      if (this.isBpcList) {
        this.selectedBpcUser.emit(this.users[index]);
      } else {
        this.selectedUser.emit(this.users[index]);
      }
    }
  }

  cancelUser(): void {
    const index = this.users.findIndex(item => item.checked);
    if (this.manager && index > -1) {
      this.selectedUser.emit(this.manager);
    } else {
      // resetting all checked users to false
      this.filteredUserList.map(user => {
        if (user.checked) {
          user.checked = false;
        }
      });
      // this.selectedUser.emit(null);
      this.closeModal.emit(false);
    }
  }

  addNewUserModal() {
    const modalRef = this.modalService.open(AddParticipantModalComponent, this.AddUserModalOptions);
    modalRef.componentInstance.collectionMode = true;
    modalRef.componentInstance.currentSubId = this.subEntityId;
    modalRef.componentInstance.rolesList = this.rolesList;
    modalRef.componentInstance.isFromBulkVendor = this.isFromBulkVendor;
    modalRef.componentInstance.modalResultCollection.subscribe((newUser: CreateUserInput) => {
      this.addUser(newUser);
      modalRef.close();
    });
  }

  async addUser(newUser: CreateUserInput): Promise<void> {
    if (newUser) {
      try {
        this.toastr.info('adding user...');
        newUser.role = RoleEnum.CUSTOM;
        const newUserString = await this.authService.addNewParticipant(newUser);
        const newUserObject: any = newUserString;
        this.filteredUserList.unshift(newUserObject);
        this.toastr.success('User added successfully');
      } catch (e) {
        const message = UtilsService.msgFromError(e);
      }
    }
  }

  getRoleName(id: string): string {
    return UtilsService.capitalizeFirstLetter(
      this.userService.roleIDToNameMapper[id] ? this.userService.roleIDToNameMapper[id] : ''
    );
  }
  isEllipsisActive(element: any): void {
    const isTooltipVisible = element.scrollWidth > element.clientWidth;
    this.isUserNameEllipsis = isTooltipVisible;
  }
}
