import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'app/shared/file.service';
import {
  CreateEntityInput,
  EntityTypeEnum,
  GetUserQuery,
  S3FileInput,
  StandardType,
  EntityQuestionSettings,
  GetRoleQuery,
  GetEntityQuery,
  CreateUserInput,
  UpdateUserInput,
  FileTypeEnum,
} from 'app/API.service';
import { IndustryEnum } from 'app/shared/enums/brownAndBrown.enum';
import { EntityService, RequiredStandard } from 'app/shared/entity.service';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { RiskOption } from 'models/generator.model';
import { QuestionSettingsEnum } from 'app/shared/enums/question-card.enum';
import { NgbDateParserFormatter, NgbActiveModal, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'app/shared/user.service';
import { DomainFrameworkService } from 'app/shared/domain-framework.service';
import { Common } from 'app/constants';
import { UsersSettingConstant } from 'app/users-settings/users-settings.constant';
import { FrameworkEnumTypes } from 'app/shared/enums/framework-enum-types.enum';
import orderBy from 'lodash/orderBy';

interface Chapters {
  id: number;
  name: string;
}

@Component({
  selector: 'cygov-add-entity-modal',
  templateUrl: './add-entity-modal.component.html',
  styleUrls: ['./add-entity-modal.component.scss'],
})
export class AddEntityModalComponent implements OnInit {
  @Output() modalResult = new EventEmitter<any>();
  newEntity: CreateEntityInput;
  imageFile: any;
  riskFramework: RiskOption;
  s3Input: S3FileInput;
  standardList: RequiredStandard[];
  standardListCopy: RequiredStandard[] = [];
  frameworkTypeOpt = [EntityTypeEnum.RISK_FRAMEWORK, EntityTypeEnum.COMPLIANCE_FRAMEWORK];
  displayStandardList: boolean;
  industryOpt = Object.keys(IndustryEnum);
  riskFrameWorkOpt: RiskOption[] = [];
  entityType: EntityTypeEnum;
  startingDate: Date;
  deadlineDate: Date;
  DEADLINE_DATE = 'deadlineDate';
  ASSESSMENT_DATE = 'startingDate';
  currentDate: NgbDateStruct;
  auditMode: boolean = false;
  newLogoUploaded = false;
  uploadedLogo: any;
  placeholderMandatory: string = 'Select Industry *';
  isMpl: boolean = false;
  MPL = Common.MPL.toLowerCase();
  // Vars for new client modal ui
  questionSettingsRadioOptions = [];
  entityQuestionSettings: EntityQuestionSettings;
  // New Design Code
  selectedStartDate: NgbDateStruct;
  selectedEndDate: NgbDateStruct;
  endCalendar: NgbInputDatepicker;
  startCalendar: NgbInputDatepicker;
  showFrameworkNameTooltip: boolean = false;
  managerUsers: any;
  allRoles: GetRoleQuery[] = [];
  rootEntityId: string;
  currentUser: any;
  currentUserRole: GetRoleQuery;
  loading = true;
  chapters: Chapters[] = [];
  selectedChapters: any = [];
  rootEntity: GetEntityQuery;
  newAddedUsers: any[] = [];
  RiskFrameworkEnum: any;

  constructor(
    private fileService: FileService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public activeModal: NgbActiveModal,
    private parserFormatter: NgbDateParserFormatter,
    private entityService: EntityService,
    private domainFrameworkService: DomainFrameworkService,
    private userService: UserService
  ) {
    this.RiskFrameworkEnum = this.fileService.importFrameworkEnumsFromS3(FrameworkEnumTypes.RISK_FRAMEWORK_ENUM);
  }

  async ngOnInit(): Promise<void> {
    let cateCounter = 0;
    this.chapters = (await this.entityService.getCategoriesFromCRB()).map(category => {
      return {
        id: cateCounter++,
        name: category,
      };
    });

    this.loading = true;
    try {
      // TODO: Add input "Select Project Manager" it's mandatory. In the mean time it will be the current user
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.rootEntityId = entityId;
      this.rootEntity = await this.entityService.getEntity(this.rootEntityId);
      this.isMpl = this.rootEntity.name.toLowerCase().includes(this.MPL);
      this.isAudit(this.isMpl);
      this.currentUser = await this.userService.getCurrentUser();
      this.currentUserRole = this.userService.getCurrentRole();
      this.currentUser.role =
        this.currentUserRole && this.currentUserRole.name
          ? this.currentUserRole.name.toUpperCase()
          : this.currentUser.role;
      this.managerUsers = await this.getAllUserList();
      this.updateRoles();
      this.entityType = this.router.url.includes('clients') ? EntityTypeEnum.ROOT_ENTITY : EntityTypeEnum.CHILD_ENTITY;
      this.newEntity = EntityService.initEntity(this.entityType, this.currentUser.id, entityId);
      this.standardList = this.entityService.initStandardList();
      // Copying standardList to standardListCopy because it was not showing other newly added Frameworks in the list (on clientside)
      this.standardListCopy = this.standardList;
      this.displayStandardList = this.router.url.includes('multi-entity');

      this.prepareRiskFrameworkOptions();
      this.applyFrameworkSettings();
      this.currentDate = UtilsService.getDateInNgbDateStructFormat(new Date());
      // New multi entity UI variables
      this.questionSettingsRadioOptions = [
        {
          id: 'question-type',
          title: 'Question Type',
          options: [
            { name: QuestionSettingsEnum.COLLABORATIVE, checked: true },
            { name: QuestionSettingsEnum.SINGLE, checked: false },
          ],
        },
        {
          id: 'artifact',
          title: 'Artifacts',
          options: [
            { name: QuestionSettingsEnum.OPTIONAL, checked: true },
            { name: QuestionSettingsEnum.MANDATORY, checked: false },
          ],
        },
        {
          id: 'manager-approval',
          title: 'Reviewer Approval',
          // options: [
          //   { name: QuestionSettingsEnum.REQUIRED, checked: false },
          //   { name: QuestionSettingsEnum.NOT_REQUIRED, checked: true },
          // ],
        },
      ];

      // getting the initial default dates
      const localStart = new Date();
      const localEnd = new Date();
      localEnd.setDate(localStart.getDate() + 1);
      this.selectedStartDate = UtilsService.getDateInNgbDateStructFormat(localStart.getTime());
      this.selectedEndDate = UtilsService.getDateInNgbDateStructFormat(localEnd.getTime());
      this.startingDate = JSON.parse(JSON.stringify(this.selectedStartDate));
      this.deadlineDate = JSON.parse(JSON.stringify(this.selectedEndDate));
      this.loading = false;
    } catch (err) {
      console.log('Error ==>>', err);
    }
  }

  /**
   * temporary find to find the role of the user will be removed after the connection
   */
  updateRoles(): void {
    if (this.managerUsers && this.managerUsers.length) {
      this.managerUsers.forEach(user => {
        user.role = this.userService.getRequiredRole(user.roleId).name.toUpperCase();
      });
    }
  }

  applyFrameworkSettings(): void {
    const frameworkSettings = this.domainFrameworkService.domainSettingsData;

    // risk
    this.riskFrameWorkOpt = this.riskFrameWorkOpt.filter(item =>
      frameworkSettings.RISK_FRAMEWORKS.find(riskFramework => {
        return item.key?.toLowerCase() === riskFramework.key?.toLowerCase() && !!riskFramework.status;
      })
    );

    // compliance
    this.standardList = this.standardList.filter(standard =>
      frameworkSettings.COMPLIANCE_FRAMEWORKS.find(complianceFramework => {
        return standard.key?.toLowerCase() === complianceFramework.key.toLowerCase() && !!complianceFramework.status;
      })
    );
    this.standardList = orderBy(this.standardList, 'name', 'asc');
  }

  prepareRiskFrameworkOptions(): void {
    const riskList =
      UtilsService.isBnB || UtilsService.isBnBCyberSite
        ? [this.RiskFrameworkEnum.NIST_CSF_BB.split(' ').join('_')]
        : Object.keys(this.RiskFrameworkEnum);
    for (const enumMember of riskList) {
      this.riskFrameWorkOpt.push({
        key: enumMember,
        name: this.RiskFrameworkEnum[enumMember],
        value: this.RiskFrameworkEnum[enumMember],
      });
    }
    this.riskFrameWorkOpt = orderBy(this.riskFrameWorkOpt, 'name', 'asc');
  }

  toggleStandard(event, standard: RequiredStandard): void {
    UtilsService.stopBubbling(event);
    standard.checked = !standard.checked;
  }

  async imgHandler(eventFile: Event): Promise<void> {
    try {
      await this.fileService.verifyUploadFile(eventFile, FileTypeEnum.LOGO);
      this.s3Input = await this.fileService.convertToS3Input(eventFile, this.newEntity.id);
      this.uploadedLogo = await this.fileService.getUploadedImg(eventFile);
      this.newLogoUploaded = true;
    } catch (error) {
      this.logger.error('Image Handler  Error =', error);
      this.toastr.error(error);
      eventFile = null;
      this.s3Input = null;
      this.uploadedLogo = null;
      this.newLogoUploaded = false;
    }
  }

  isValid(entity: CreateEntityInput): boolean {
    let valid = true;
    if (!entity.name) {
      this.toastr.error('Entity name is required');
      valid = false;
    } else {
      if (entity.name.length > 20) {
        this.toastr.error('Entity name cannot exceed 20 characters');
        valid = false;
      }
    }
    if (this.entityType === EntityTypeEnum.ROOT_ENTITY) {
      if (!entity.industry) {
        this.toastr.error('Entity Industry is required');
        valid = false;
      }
    }
    if (this.displayStandardList && (!this.startingDate || !this.deadlineDate)) {
      this.toastr.error('Assessment date is required');
      valid = false;
    }
    if (!this.auditMode && !this.riskFramework && entity.entityType !== 'ROOT_ENTITY') {
      this.toastr.error('Risk Framework is required');
      valid = false;
    }
    if (this.isMpl && this.selectedChapters && !this.selectedChapters.length) {
      this.toastr.error('Chapter is required');
      valid = false;
    }
    return valid;
  }

  /**
   * Setting the startingDate or deadlineDate in this function
   * @param date The actual NGB Date Struct from calendar
   * @param dateType The Date type , either Starting Date or Deadline Date
   */
  dateChanged(date, dateType: string): void {
    this[dateType] = date;
  }

  emitEntity(
    entity: CreateEntityInput,
    s3Input: S3FileInput,
    standardList: RequiredStandard[],
    newAddedUsers: CreateUserInput[] | UpdateUserInput[]
  ): void {
    const requiredStandard: RequiredStandard[] = standardList.filter(standard => standard.checked);
    if (!UtilsService.isCRB && this.riskFramework) {
      requiredStandard.push({
        key: this.riskFramework.key,
        name: this.riskFramework.value,
        type: StandardType.RISK_FRAMEWORK,
        checked: true,
      });
    } else {
      requiredStandard.push({
        key: this.RiskFrameworkEnum.CROSS_RIVER.split(' ').join('_'),
        name: this.RiskFrameworkEnum.CROSS_RIVER,
        type: StandardType.RISK_FRAMEWORK,
        checked: true,
      });
    }
    entity.timeline.collectionDate = this.assignDate(this.deadlineDate, this.DEADLINE_DATE);
    entity.projectDeadline = entity.timeline.collectionDate;
    entity.timeline.initiationDate = this.assignDate(this.startingDate, this.ASSESSMENT_DATE);
    entity.tierSelected = 4;
    this.modalResult.emit({
      entity,
      s3Input,
      requiredStandard,
      newAddedUsers,
      selectedChapters: this.selectedChapters,
    });
  }

  assignDate(dateStr, dateType: string): number {
    dateStr = this.parserFormatter.format(dateStr);
    const date = new Date(dateStr).getTime();
    if (dateType === this.DEADLINE_DATE) {
      this.newEntity.projectDeadline = date;
      this.newEntity.timeline.collectionDate = date;
    } else {
      this.newEntity.timeline.initiationDate = date;
    }
    return date;
  }

  handleFirstTabUpdatedListOutput(event) {
    this.riskFramework = event.find(r => r.checked);
  }

  handleSecondTabUpdatedListOutput(event) {
    this.standardList = event;
  }

  // Store the selected question setting in the enity object
  selectedQuestionSettingsEmit({ name }): void {
    if (name === QuestionSettingsEnum.COLLABORATIVE || name === QuestionSettingsEnum.SINGLE) {
      this.newEntity.defaultQuestionSettings.isCollaborative = name === QuestionSettingsEnum.COLLABORATIVE;
    }
    if (name === QuestionSettingsEnum.OPTIONAL || name === QuestionSettingsEnum.MANDATORY) {
      this.newEntity.defaultQuestionSettings.isArtifactsMandatory = name === QuestionSettingsEnum.MANDATORY;
    }
    // if (name === QuestionSettingsEnum.REQUIRED || name === QuestionSettingsEnum.NOT_REQUIRED) {
    //   this.newEntity.defaultQuestionSettings.isApprovalRequired = name === QuestionSettingsEnum.REQUIRED;
    // }
  }

  /**
   * In this Method we take the Users from Root Entity and subEntities
   * @returns is type of GetUserQuery List
   */
  async getAllUserList(): Promise<GetUserQuery[]> {
    const subEntities = await this.entityService.getSubEntitiesList(this.rootEntityId, true);
    await this.userService.getRolesByDefaultOrEntityId(UsersSettingConstant.default, true);
    const childEntityIds = subEntities.map(entity => {
      return entity.id;
    });
    const users = await this.userService.getAllUsersForEntityWithRoleId(
      this.rootEntityId,
      childEntityIds as [string],
      this.currentUserRole
    );
    return users ? users : [];
  }

  /**
   * @param user is type of GetUserQuery
   * this method is invoked on the emit of cygov-user-listing
   * to assign the manager
   */
  assignManager(user: GetUserQuery): void {
    if (user) {
      this.newEntity.projectManager = user.id;
      this.newEntity.projectManagerName = user.name;
    }
  }
  isAudit(value): boolean {
    return (this.auditMode = this.isMpl ? value : false);
  }
  addToSelectedChapters(chapter) {
    const index = this.selectedChapters.indexOf(chapter.name);
    if (index < 0) {
      this.selectedChapters.unshift(chapter.name);
    } else {
      this.selectedChapters.splice(index, 1);
    }
  }
  addUser(e): void {
    this.newAddedUsers = e;
  }
}
