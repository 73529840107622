import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { GetRoleQuery } from 'app/API.service';
import { UserService } from 'app/shared/user.service';
import { UsersSettingConstant } from 'app/users-settings/users-settings.constant';
import { Observable } from 'rxjs';
import { AppLevelRoleEnum } from '../enums/appLevelRoles.enum';
import { AppLevelRoleEnumThirdParty } from '../enums/appLevelRoles.enum';
import { UtilsService } from '../utils.service';
import { EntityService } from '../entity.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  mainRoute: string = null;
  subRoute: string = null;
  entityId: string = null;
  constructor(private userService: UserService, private router: Router, private entityService: EntityService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.verifyRoute(state.url);
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.verifyRoute(state.url);
  }

  CheckRouteEnableInEntity(entity) {
    if (entity?.solutionStatus && this.mainRoute === 'first-party' && !entity?.solutionStatus?.firstParty) {
      return false;
    }

    if (this.mainRoute === 'third-party' && !entity?.solutionStatus?.thirdParty) {
      return false;
    }

    if (this.mainRoute === 'board' && !entity?.solutionStatus?.boardView) {
      return false;
    }

    return true;
  }

  async verifyRoute(url: string = '') {
    try {
      [this.mainRoute, this.entityId, this.subRoute] = this.returnRoutes(url);
      await this.userService.getCurrentUser();
      const role = this.userService.currentUserRole;

      if (this?.entityId) {
        const entity = await this.entityService.getEntity(this.entityId, true);
        const isAllowed = [AppLevelRoleEnum.VENDOR, AppLevelRoleEnum.PARTICIPANT].includes(role?.name?.toLowerCase()) ? true : this.CheckRouteEnableInEntity(entity);

        if (!isAllowed) {
          if (entity?.solutionStatus?.firstParty) {
            await this.router.navigate([`first-party/${this.entityId}/upperdeck`]);
          }
          if (entity?.solutionStatus?.thirdParty) {
            await this.router.navigate([`third-party/${this.entityId}/overview`]);
          }
        }
      }
      if (role && role.screenPermissions) {
        const screenPermissions = JSON.parse(role.screenPermissions);
        [this.mainRoute, this.entityId, this.subRoute] = this.returnRoutes(url);
        if (this.subRoute === 'default-settings') {
          this.subRoute = this.subRoute.split('-').join(' ');
        }
        this.checkForQueryParams();
        if (this.mainRoute && screenPermissions[this.mainRoute]) {
          if (screenPermissions[this.mainRoute].length && screenPermissions[this.mainRoute].includes(this.subRoute)) {
            return true;
          }
        }
        if (
          screenPermissions['basic-routes'] &&
          screenPermissions['basic-routes'].length &&
          screenPermissions['basic-routes'].includes(this.mainRoute)
        ) {
          return true;
        } else if (role && role?.name?.toLowerCase() === AppLevelRoleEnum.VENDOR) {
          return true;
        }
        const newUrl = this.checkForFirstTimeLandingPages(url, role);
        // if newUrl has value, it means module is loading first time and it will land according to the permission.
        // uf newUrl is null, it will check for the third party case for collection and gaps.
        if (newUrl) {
          await this.router.navigate([newUrl]);
          return true;
        } else if (screenPermissions[this.mainRoute] && (this.subRoute === 'collection' || this.subRoute === 'gaps')) {
          return true;
        }
      } else if (!role) {
        await this.router.navigate(['/login']);
        return true;
      }
      this.userService.currentUserValue = null;
      await this.router.navigate(['/not-authorized']);
    } catch (e) {
      await this.router.navigate(['/login']);
      return Promise.reject(false);
    }
  }
  returnRoutes(url: string = ''): Array<string> {
    const routeArray: Array<string> = url.split('/');
    // routeArray have following this paths
    // routeArray[1] = contains the main route
    // routeArray[2] = contains entityId or subRoute (in case of bnb management)
    // routeArray[3] = contains the subMenu.
    if (
      UtilsService.isBnB &&
      routeArray &&
      routeArray.length &&
      routeArray[1].toLowerCase() === UsersSettingConstant.clients.toLowerCase()
    ) {
      return [routeArray[1], '', routeArray[2]];
    } else if (routeArray[1].toLowerCase() === UsersSettingConstant.clients.toLowerCase()) {
      return [routeArray[1], null, null];
    } else {
      return [routeArray[1], routeArray[2], routeArray[3]];
    }
  }

  checkForQueryParams() {
    // splitting the main and sub route for getting the query params.
    const mainRouteQueryParams = this.mainRoute.split('?');
    const subRouteQueryParams = this.subRoute ? this.subRoute.split('?') : null;

    // after splitting assigning the main route and sub routes.
    this.mainRoute = mainRouteQueryParams && mainRouteQueryParams.length ? mainRouteQueryParams[0] : this.mainRoute;
    this.subRoute = subRouteQueryParams && subRouteQueryParams.length ? subRouteQueryParams[0] : this.subRoute;
  }

  checkForFirstTimeLandingPages(url: string, role: GetRoleQuery): string {
    if (!this.subRoute && this.mainRoute === AppLevelRoleEnumThirdParty.ThirdPartyRole) {
      this.subRoute = 'overview';
    }

    const screenPermissions = role ? JSON.parse(role.screenPermissions) : [];
    if (
      screenPermissions &&
      screenPermissions[AppLevelRoleEnumThirdParty.ThirdPartyRole] &&
      screenPermissions[AppLevelRoleEnumThirdParty.ThirdPartyRole].length &&
      this.mainRoute === AppLevelRoleEnumThirdParty.ThirdPartyRole &&
      this.subRoute === 'overview'
    ) {
      this.subRoute =
        screenPermissions[AppLevelRoleEnumThirdParty.ThirdPartyRole][0] === AppLevelRoleEnumThirdParty.CustomVendors
          ? screenPermissions[AppLevelRoleEnumThirdParty.ThirdPartyRole][1]
          : screenPermissions[AppLevelRoleEnumThirdParty.ThirdPartyRole][0];
      return `${this.mainRoute}/${this.entityId}/${this.subRoute}`;
    } else if (
      screenPermissions &&
      screenPermissions['first-party'] &&
      screenPermissions['first-party'].length &&
      this.mainRoute === 'first-party' &&
      this.subRoute === 'upperdeck'
    ) {
      this.subRoute = screenPermissions['first-party'][0];
      return `${this.mainRoute}/${this.entityId}/${this.subRoute}`;
    } else if (screenPermissions && screenPermissions.board && screenPermissions.board.length && !this.subRoute) {
      this.subRoute = screenPermissions.board[0];
      return `${this.mainRoute}/${this.entityId}/${this.subRoute}`;
    } else {
      return null;
    }
  }
}
